import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import $ from 'jquery';
declare global {
  interface JQuery {
    marquee(config: any): void;
  }
}

@Component({
  selector: 'pla-compare-loader',
  templateUrl: './compare-loader.component.html',
  styleUrls: ['./compare-loader.component.scss'],
})
export class CompareLoaderComponent implements OnInit {
  @Input() public almostComplete = new EventEmitter<void>();
  @Output() public complete = new EventEmitter<void>();
  public progress = 0;
  public mainLoader: boolean = true;
  public intervalClock = -1;
  public endClock = -1;
  public siteNames: string[] = [
    'RegTransfers',
    'DVLA',
    'CarReg',
    'Plates4Less',
    'AbsoluteReg',
  ];
  public loadingTexts: string[] = [
    'Verifying registration details...',
    'Checking DVLA records...',
    'Looking up sales history...',
    'Checking prices from multiple dealers and online sources...'
  ]

  constructor() { }

  ngOnInit(): void {
    this.Start(66, 0.3);

    this.almostComplete.subscribe(() => {
      this.mainLoader = false;

      if (this.progress < 90) {
        this.Start(32, 1, () => {
          this.FinalStretch();
        });
      } else {
        this.FinalStretch();
      }
    });
  }

  private FinalStretch(): void {
    this.endClock = window.setInterval(() => {
      if (this.progress >= 99) {
        window.clearInterval(this.endClock);
        setTimeout(() => {
          this.mainLoader = true;
          this.complete.emit();
        }, 100);
        return;
      }

      this.progress += 1;
    }, 100);
  }

  public Start(delay: number, increment: number, callback?: () => void): void {
    window.clearInterval(this.intervalClock);
    window.clearInterval(this.endClock);

    this.intervalClock = window.setInterval(() => {
      if (this.progress >= 90) {
        window.clearInterval(this.intervalClock);
        if (callback) callback();
        return;
      }
      this.progress += increment;
    }, delay);
  }
}
