<div class="d-block d-md-flex position-relative justify-content-between align-items-stretch gap-5">
    <div class="d-md-block mb-3 mb-md-0 pt-2 start-0 end-0 position-sticky nr-info"
        style="top: 92px; height: fit-content;">
        <div class="py-3 px-4 rounded-3" style="background-color: #2D515A;">
            <p class="fs-md-18 text-white mb-3">Potential Returns from Investing in New Issue Number Plates</p>
            <p class="fs-md-14 text-white mt-0 mb-3">
                Investing in new issue number plates can yield impressive financial returns, as illustrated in the table
                below. These plates were purchased at or shortly after their DVLA release and later resold at
                substantial profits.</p>
            <p class="fs-md-14 text-white mt-0 mb-3">
                ROI (Return on Investment), shown in the final column, indicates the percentage increase in value from
                initial purchase to resale. For example, the registration GU11TAR, bought for £399 in 2011, was resold
                for £9,219 in 2023, delivering a staggering ROI of 2211%. Likewise, SP19YDR, purchased for £399 in 2019
                and sold for £6,551 in 2020, achieved an astounding 1542% ROI in just one year.</p>
            <p class="fs-md-14 text-white my-0">
                These examples highlight the potential financial gains from speculative purchases of new issue private
                number plates, especially those resembling popular names or words. This market research shows that, with
                careful selection, private plates can be a highly attractive alternative investment.</p>
        </div>
    </div>
    <div class="flex-fill" style="max-width: 1000px;">
        <table class="table text-center">
            <thead>
                <tr>
                    <th style="max-width: 200px;" class="text-start text-md-start">
                        <div class="d-flex align-items-center justify-content-between pe-2 fs-md-12 pointer"
                            (click)="sortChange('registration')">
                            <span>Registration</span>
                            <mat-icon
                                class="sort-{{isSorting('registration')}}">{{sortingIcon('registration')}}</mat-icon>
                        </div>
                    </th>
                    <th class="text-center" style="max-width: 120px;">
                        <div style="max-width: 120px;"
                            class="d-flex align-items-center justify-content-between pe-2 fs-md-12 pointer mx-auto"
                            (click)="sortChange('releasePrice')">
                            <div></div>
                            <span>Release Price</span>
                            <mat-icon
                                class="sort-{{isSorting('releasePrice')}}">{{sortingIcon('releasePrice')}}</mat-icon>
                        </div>
                    </th>
                    <th class="text-center" style="max-width: 120px;">
                        <div style="max-width: 120px;"
                            class="d-flex align-items-center justify-content-between pe-2 fs-md-12 pointer mx-auto"
                            (click)="sortChange('resalePrice')">
                            <div></div>
                            <span>Resale Price</span>
                            <mat-icon
                                class="sort-{{isSorting('resalePrice')}}">{{sortingIcon('resalePrice')}}</mat-icon>
                        </div>
                    </th>
                    <th class="text-center">
                        <div style="width: 90px; min-width: 90px; max-width: 90px;"
                            class="d-flex align-items-center justify-content-between fs-md-12 pointer mx-auto"
                            (click)="sortChange('roi')">
                            <div></div>
                            <span>ROI</span>
                            <mat-icon class="sort-{{isSorting('roi')}}">{{sortingIcon('roi')}}</mat-icon>
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let row of displayResults" (click)="GotoPlate(row.registration)" style="cursor: pointer"
                    class="hover-interact">
                    <td>
                        <div class="py-2 d-flex align-items-center justify-content-start">
                            <pla-plate [rawPlate]="row.registration" [customClass]="'flat shaded'" [plateSize]="'ns-s'"
                                [smallPlate]="false" [mobileTiny]="true"></pla-plate>
                        </div>
                    </td>
                    <td>
                        <div style="max-width: 120px;" class="mx-auto">{{row.releasePrice | currency: 'GBP' : "symbol" :
                            "1.0-0"}}<br />({{row.releaseYear}})</div>
                    </td>
                    <td>
                        <div style="max-width: 120px;" class="mx-auto">{{row.resalePrice | currency: 'GBP' : "symbol" :
                            "1.0-0"}}<br />({{row.resaleYear}})</div>
                    </td>
                    <td>
                        <div class="fs-md-14 mx-auto" style="color: #06A1C9 !important; max-width: 90px;">
                            {{row.roi}}<span style="color: #06A1C9 !important;" class="fs-md-14"
                                *ngIf="row.roi > 0">%</span></div>
                    </td>
                </tr>
            </tbody>
        </table>
        <mat-paginator [length]="viewResults.length" [pageSize]="paginationPageSize" [hidePageSize]="true"
            (page)="paginate($event)">
        </mat-paginator>
    </div>
</div>