<div>
  <div *ngIf="!registrationHistory.loading">
    <div class="chart-container" *ngIf="hasSoldData(2, true)">
      <pla-chart-price-history [data]="registrationHistoryPoints"></pla-chart-price-history>
    </div>
    <div *ngIf="!hasSoldData(2, true)">
      <ng-content select="[chart]"></ng-content>
    </div>
    <pla-sold-data-table *ngIf="this.hasSoldData(1, true)" [data]="registrationHistory.data"></pla-sold-data-table>
    <div *ngIf="!hasSoldData(1, true)">
      <ng-content select="[table]"></ng-content>
    </div>
  </div>
</div>