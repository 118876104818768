import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'pla-router-button',
  templateUrl: './router-button.component.html',
  styleUrls: ['./router-button.component.css']
})
export class RouterButtonComponent  {

  @Input('route') private route: string;
  
  constructor(private router: Router) { }

  public navigate(): void {
    this.router.navigate([this.route]);
  }
}
