<!-- OVERLAY PANEL -->
<div [style.display]="opened ? 'flex' : 'none'"
    class="position-fixed bottom-0 start-0 end-0 top-0 bg-overlay align-items-stretch justify-content-between flex-column"
    style="z-index: 1000">
    <div (click)="Toggle(false)" class="flex-fill"></div>
    <!-- PANEL -->
    <div class="bg-white above-shadow-sm position-fixed bottom-0 start-0 end-0" style="
      border-top-left-radius: 20px !important;
      border-top-right-radius: 20px !important;
    ">
        <!-- CONTENT -->
        <div class="py-3 px-4">
            <div class="d-flex align-items-center justify-content-end text-dark">
                <mat-icon (click)="Toggle(false)">close</mat-icon>
            </div>
            <div>
                <ng-content></ng-content>
            </div>
        </div>

        <!-- ACTIONS -->
        <div>
            <div style="background-color: #007850; cursor: pointer" class="w-100 py-3" (click)="Toggle(false)">
                <p class="w-100 mb-0 text-white text-center">{{actionText}}</p>
            </div>
        </div>
    </div>
</div>