<div *ngIf="!loading && history && history.length > 0">
    <div class="px-4 px-md-4 d-flex align-items-center justify-content-between">
        <p class="fw-bolder fs-md-16 my-1">{{ title }}</p>

        <p class="fw-light fs-md-14 my-1 text-decoration-underline text-muted" (click)="ClearHistory()">Clear</p>
    </div>
    <div class="w-100 py-4 px-4 px-md-4" style="overflow-x: auto; overflow-y: hidden; background-color: #F8F8F8;">
        <div class="d-flex align-items-stretch justify-content-start gap-4">
            <ng-container *ngFor="let h of history">
                <div class="px-1" (click)="ClickHandle(h)">
                    <pla-plate [rawPlate]="h.registration || h.term" [openModal]="false" [mobileTiny]="true"
                        [plateSize]="'smaller'" [customClass]="'flat shaded'"></pla-plate>
                    <p class="text-nowrap mt-1 mb-1 lh-sm w-100 text-center">{{h.price || h.subText}}</p>
                    <p class="text-nowrap fs-md-12 fs-mb-12 lh-sm mt-0 mb-0 text-muted w-100 text-center">Updated
                        {{h.time | date:
                        'dd/MM/yy' }}</p>
                </div>
            </ng-container>
        </div>
    </div>
</div>