<div class="modal-container">
  <div class="details">
    <div>
      <h1>{{ registerScreen ? "Sign Up!" : "Log In!" }}</h1>
      <p class="text-center mb-0">
        Register FREE to view Price History, Favourite plates and more.
      </p>
    </div>
  </div>

  <form
    class="form-container"
    [formGroup]="registerForm"
    *ngIf="registerScreen"
  >
    <mat-form-field appearance="outline">
      <input
        matInput
        required
        formControlName="email"
        placeholder="Email"
        name="email"
        type="email"
      />
      <mat-error
        *ngIf="
          registerForm.controls.email.hasError('email') &&
          !registerForm.controls.email.hasError('required')
        "
      >
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="registerForm.controls.email.hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <input
        matInput
        required
        formControlName="password"
        placeholder="Password"
        name="password"
        type="password"
      />
      <mat-error *ngIf="registerForm.controls.password.hasError('required')">
        Password is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <input
        matInput
        required
        formControlName="passwordRepeat"
        placeholder="Repeat Password"
        name="password"
        type="password"
      />
      <mat-error
        *ngIf="registerForm.controls.passwordRepeat.hasError('required')"
      >
        Repeat Password is <strong>required</strong>
      </mat-error>
    </mat-form-field>
    <div class="response-errors" *ngIf="registerErrors">
      <ng-container *ngFor="let error of registerErrors">
        <p>{{ error }}</p>
      </ng-container>
    </div>

    <p class="text-center">Password requires at least one number, and one special character.</p>

    <button mat-flat-button color="accent" class="mx-auto" (click)="register()">Sign Up</button>

    <button mat-stroked-button class="mt-1 mx-auto" [color]="'warn'" mat-dialog-close>
      Cancel
    </button>

    <span class="cancel-action mt-2" (click)="setLoginPage(true)"
      >Already have an account? Log in.</span
    >
  </form>

  <form class="form-container" [formGroup]="loginForm" *ngIf="!registerScreen">
    <mat-form-field appearance="outline">
      <input
        matInput
        required
        formControlName="email"
        placeholder="Email"
        name="email"
        type="email"
      />
      <mat-error
        *ngIf="
          loginForm.controls.email.hasError('email') &&
          !loginForm.controls.email.hasError('required')
        "
      >
        Please enter a valid email address
      </mat-error>
      <mat-error *ngIf="loginForm.controls.email.hasError('required')">
        Email is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <input
        matInput
        required
        formControlName="password"
        placeholder="Password"
        name="password"
        type="password"
      />
      <mat-error *ngIf="loginForm.controls.password.hasError('required')">
        Password is <strong>required</strong>
      </mat-error>
    </mat-form-field>

    <button mat-raised-button color="accent" class="mx-auto" (click)="login()">Log In</button>

    <button mat-stroked-button class="mt-1 mx-auto" [color]="'warn'" mat-dialog-close>
      Cancel
    </button>

    <span class="cancel-action mt-2" (click)="setLoginPage(false)"
      >Don't have an account? Sign Up.</span
    >
  </form>
</div>
