<!-- LOCKED CONTENT -->
<ng-container *ngIf="contentLocked; else contentUnlocked">
  <!-- SHOW BUTTON -->
  <ng-container *ngIf="showButton; else customContent">
    <ng-container [ngSwitch]="buttonType">
      <button
        *ngSwitchCase="'button'"
        (click)="requestSignupModal()"
        mat-flat-button
      >
        {{ loginRequiredMessage }}
      </button>
      <span
        class="label-button"
        (click)="requestSignupModal()"
        *ngSwitchDefault
        >{{ loginRequiredMessage }}</span
      >
    </ng-container>
  </ng-container>

  <!-- Pass from parent -->
  <ng-template #customContent>
    <div (click)="requestSignupModal()" class="pointer hover-interact">
      <ng-content select="[lockedContent]"></ng-content>
    </div>
  </ng-template>
</ng-container>

<!-- unlocked content -->
<ng-template #contentUnlocked>
  <ng-content select="[unlockedContent]"></ng-content>
</ng-template>
