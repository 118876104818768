<div class="page pt-2 pt-md-4">
    <div class="content-container extended">
        <div class="ps-5 side-container mb-3">
            <div class="pt-0 position-relative">
                <pla-side-nav></pla-side-nav>
            </div>

            <div class="bg-light">
                <!-- <pla-featured-plates [featureType]="rsFeatured" [plateSize]="'smaller'" [priceFontSize]="16"
                    [maxCount]="10" [sortBy]="'price'" [plateClass]="'flat shaded'" [showIndex]="false"></pla-featured-plates> -->
            </div>
        </div>
        <div class="center-content-container position-relative">
            <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
                <div>
                    <!-- BREADCRUMBS -->
                    <div class="w-100">
                        <div
                            class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
                            <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
                            <span>/</span>
                            <a style="white-space: pre; color: #327b8d" class="text-decoration-none d-block"
                                [routerLink]="['/articles']">Resources</a>
                            <span>/</span>
                            <span style="color: #939393; white-space: pre" class="text-decoration-none d-block">Number
                                Plate Types</span>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-0 px-md-1 py-0 me-0">
                <div class="px-0 px-md-2">

                    <div class="mx-3 mx-md-0">

                        <div class="mt-4 mb-4 border-bottom border-2" style="border-color: #3791A8 !important;">
                            <p class="fs-mb-16 fs-md-18 fw-bold text-start mx-0">How do you Transfer a Private Plate
                            </p>
                        </div>

                        <div>
                            <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                Transferring a private number plate or personal plate to another vehicle is a
                                straightforward process in the UK. Whether you're looking to upgrade your vehicle or
                                simply want to move your cherished registration, here's a step-by-step guide on how to
                                transfer your private number plate.
                            </p>
                            <div>
                                <ul class="platex-list mb-2">
                                    <li>Eligibility Criteria</li>
                                </ul>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-0">
                                    Before transferring a private number plate, ensure that both vehicles meet the
                                    following criteria:
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-0">
                                    Both vehicles must be registered with the DVLA in the UK.
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-0">
                                    They must be able to move under their own power.
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-0">
                                    They must require an MOT or heavy goods vehicle (HGV) test certificate.
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-0">
                                    Both vehicles must be available for inspection if requested by the DVLA.
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-0">
                                    Both vehicles must have been taxed or had a SORN continuously for the past 5 years.
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-0">
                                    Both vehicles must be currently taxed or have a SORN in place. If SORN has been in
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-0">
                                    place for over 5 years, the vehicle must be taxed and have an MOT certificate.
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                                    For historic (classic) vehicles, a current MOT certificate is required, even if
                                    usually exempt.
                                </p>
                            </div>

                            <div>
                                <ul class="platex-list mb-2">
                                    <li>Application Process</li>
                                </ul>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                                    Online Application:
                                </p>
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                                    Visit the DVLA website: Navigate to the DVLA’s personalised registration service.
                                    Complete the online form: Provide the necessary details about both vehicles and
                                    submit the application.
                                    Payment: There is no fee for the transfer process if done online.
                                    Immediate Assignment: If no inspection is required, the private number plate will be
                                    assigned immediately.
                                    Receive Documentation: You will receive a new log book (V5C) for both vehicles
                                    showing the updated registration numbers.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div style="height: 50px;"></div>

                    <div>
                        <div class="container-fluid row mx-auto">
                            <div class="col-12 col-md-5 px-0">
                                <img src="https://cdn.platex.co.uk/pages/articles/v317_template.png"
                                    class="d-block w-100" />
                                <a href="https://assets.publishing.service.gov.uk/media/662b76131179821456b81e68/v317-application-to-transfer-or-retain-a-vehicle-registration-number.pdf"
                                    class="fs-md-14 fs-mb-14 text-dark d-block mb-2 text-center text-dark">V317 - Application to
                                    Transfer a Registration</a>
                            </div>

                            <div class="col-12 col-md-2 px-0"></div>

                            <div class="col-12 col-md-5 px-0">
                                <img src="https://cdn.platex.co.uk/pages/articles/v5c_template.png"
                                    class="d-block w-100" />
                                <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2 text-center">V5C - Registration
                                    Certificate</p>
                            </div>
                        </div>
                    </div>

                    <div style="height: 50px;"></div>

                    <div>
                        <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                            Postal Application:
                        </p>
                        <p class="fs-md-14 fs-mb-14 text-dark d-block mb-1">
                            Download and Complete Form V317: The form is titled “Application to transfer or retain a
                            vehicle registration number.”
                            Prepare Additional Documents: Include the log books (V5C) for both vehicles or green 'new
                            keeper' slips with completed V62 forms.
                        </p>
                        <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                            Mail the Application: Send the completed form and documents to the DVLA.
                            Receive Documentation: You will receive new log books (V5C) showing the updated registration
                            numbers for both vehicles.
                            After Transfer
                        </p>
                        <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                            New Log Books (V5C): You will receive new log books showing the new registration numbers for
                            both vehicles.
                        </p>
                        <p class="fs-md-14 fs-mb-14 text-dark d-block mb-0">
                            Fit New Number Plates: Attach the new number plates to both vehicles before driving them.
                            Insurance Update: Inform your insurance company of the new registration numbers for both
                            vehicles.
                        </p>
                        <p class="fs-md-14 fs-mb-14 text-dark d-block mb-4">
                            Update Automatic Payment Accounts: Update your registration number for any automatic payment
                            accounts such as Congestion Charge, Low Emission Zone Charge, Ultra Low Emission Zone
                            Charge, Dart Charge, and Clean Air Zones to avoid penalties.
                        </p>

                        <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                            Important Considerations:
                        </p>
                        <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                            Cannot Transfer Q Plates: You cannot assign a private number plate starting with 'Q' or
                            'NIQ'.
                        </p>
                        <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                            Vehicle Appearance: The private number plate should not make a vehicle appear newer than it
                            is. For example, you cannot put an '07' registration on a 2003 registered vehicle.
                        </p>
                        <p class="fs-md-14 fs-mb-14 text-dark d-block mb-2">
                            Immediate Transfer: The private number plate can be transferred as soon as the application
                            is processed and approved, with the new number plates fitted to both vehicles immediately.
                        </p>
                    </div>
                    <div style="height: 50px;"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-light">
        <div class="content-container extended">
            <div class="blog-content-container position-relative py-3 mb-3 ps-5 pe-5">
                <p class="h3 fw-bolder text-start mb-2">In Other News</p>
                <pla-featured-blogs></pla-featured-blogs>
            </div>
        </div>
    </div>
</div>