import { Component, OnInit } from '@angular/core';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import { HttpLoadingResp } from 'src/app/models/httpLoadingResp';
import { Registration } from 'src/app/models/registration';
import { RegistrationService } from 'src/app/services/registration-service';
import { SearchReqResponse, SearchService } from 'src/app/services/search-service';

@Component({
  selector: 'pla-under-one-hundred',
  templateUrl: './under-one-hundred.component.html',
  styleUrls: ['./under-one-hundred.component.scss']
})
export class UnderOneHundredComponent implements OnInit {

  public termSorting: 'A-Z' | 'Z-A' | 'LOW-TO-HIGH' | 'HIGH-TO-LOW' = 'HIGH-TO-LOW';
  public raFeatured = new FeatureType('Recently Added', 'recently-added');
  public results: HttpLoadingResp<SearchReqResponse> = new HttpLoadingResp(true, undefined);

  constructor(
    private searchService: SearchService,
    private registrationService: RegistrationService
  ) { }

  ngOnInit(): void {
    this.Search();
  }


  private Search(): void {
    this.searchService.getSub200((_: Registration[]) => {
      this.results.Update(false, new SearchReqResponse('sub-200', this.registrationService.formatRegistrations(_)));
      this.TermSortChange();
    })
  }

  public TermSortChange(): void {
    var sortedData = [];
    if (this.termSorting === 'A-Z') sortedData = this.results.data.registrations.sort((a, b) => a.registration.localeCompare(b.registration));
    else if (this.termSorting === 'Z-A') sortedData = this.results.data.registrations.sort((a, b) => b.registration.localeCompare(a.registration));
    else if (this.termSorting === 'HIGH-TO-LOW') sortedData = this.results.data.registrations.sort((a, b) => b.price - a.price);
    else if (this.termSorting === 'LOW-TO-HIGH') sortedData = this.results.data.registrations.sort((a, b) => a.price - b.price);

    this.results.Update(false, new SearchReqResponse('sub-200', this.registrationService.formatRegistrations(sortedData)));
  }
}
