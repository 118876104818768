<!-- Hugh query page -->
<mat-form-field class="d-block w-100 mt-3">
    <mat-label>Describe what fitler you want to see. ie: '51', 'names'</mat-label>
    <input [(ngModel)]="wordbankFeature.filter" class="d-block w-100" matInput />
</mat-form-field>
<ng-container>
    <button *ngIf="!wordbankFeature.loading" (click)="SearchWordbank()"
        class="platex-button rounded py-2 px-3">Search</button>
    <mat-progress-spinner *ngIf="wordbankFeature.loading" class="spinner cyan" mode="indeterminate" [color]="'#327b8d'"
        [diameter]="20"></mat-progress-spinner>
</ng-container>
<ng-container *ngIf="wordbankFeature.res">
    <ng-container *ngIf="wordbankFeature.res == null || wordbankFeature.res.length <= 0">
        <p>No Results</p>
    </ng-container>

    <ng-container *ngIf="wordbankFeature.res.length > 0">
        <div class="my-2">
            <mat-checkbox [(ngModel)]="showAll">Show All</mat-checkbox>
        </div>
        <p>Count: {{ wordbankFeature.res.length }} (max 1500) (showAll? {{ showAll }})</p>
    </ng-container>

    <ng-container *ngIf="wordbankFeature.res.length > 0">
        <ng-container *ngFor="let result of wordbankFeature.res">
            <div class="d-flex align-items-center justify-content-start gap-3 mb-3 pb-3 border-bottom border-light"
                *ngIf="showAll || result.owner == 0">
                <div (click)="DeleteItem(result)" class="pointer hover-interact">
                    <mat-icon>delete</mat-icon>
                </div>
                <div class="d-flex flex-column justify-content-center align-content-center gap-2">
                    <pla-plate [rawPlate]="result.plate" [openModal]="false" [disabled]="result.sold == null"
                        [mobileTiny]="true" [plateSize]="'smaller'" [customClass]="'flat shaded'"></pla-plate>

                    <pla-plate [rawPlate]="result.term" [openModal]="false" [mobileTiny]="true" [plateSize]="'smaller'"
                        [disabled]="result.sold == null" [customClass]="'flat shaded'"></pla-plate>
                </div>
                <p class="my-0">{{ result.price / 100 | currency: 'GBP' }}</p>
                <p class="my-0">{{ result.sold === null ? '-' : result.sold ? 'SOLD' : 'AVAILABLE' }}</p>
                <div class="d-flex flex-column justify-content-center align-content-center gap-2">
                    <p class="my-0">Filters:</p>
                    <input [(ngModel)]="result.filter" disabled class="d-block w-100" matInput />
                </div>
            </div>
        </ng-container>
    </ng-container>
</ng-container>