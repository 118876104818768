import { Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BYOTermBreakdown } from 'src/app/models/byoTermResponse';
import { FeaturedInitials, InitialSearchResults } from 'src/app/models/initialsSearchResults';
import { Registration } from 'src/app/models/registration';
import {
  SearchReqResponse,
  SearchService,
} from 'src/app/services/search-service';
import { Location } from '@angular/common';
import { PageStep } from 'src/app/components/page-steps/page-steps.component';
import {
  TrackingService,
  TRACKING_SEARCH_TYPE,
} from 'src/app/services/tracking-service';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';

@Component({
  selector: 'pla-initials-search-page',
  templateUrl: './initials-search-page.component.html',
  styleUrls: ['./initials-search-page.component.scss'],
})
export class InitialsSearchPageComponent implements OnInit {
  public initialFeatured = new FeatureType('Number 1 Plates', 'initial');
  public initials: string;
  public dnf_searching: boolean = false;
  public dlf_searching: boolean = false;
  public current_searching: boolean = false;
  public prefix_searching: boolean = false;
  public suffix_searching: boolean = false;

  public initialResults: InitialSearchResults;
  public dnf_resultsChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public dlf_resultsChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public c_resultsChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public p_resultsChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public s_resultsChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();

  public featuredInitials: FeaturedInitials;

  public pageSteps: PageStep[] = [
    new PageStep().AddBody('<b>Search</b> up to 3 initials'),
    new PageStep().AddBody(
      '<b>View</b> your initials on Current, Prefix, Suffix and Dateless Registrations'
    ),
    new PageStep().AddBody(
      '<b>Compare</b> deals across the whole market to get the best possible price'
    ),
    new PageStep().AddBody(
      '<b>Buy</b> direct with us or be transferred to the dealer to checkout with cash or finance'
    ),
  ];

  constructor(
    private searchService: SearchService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private trackingService: TrackingService
  ) {
    this.initials = this.activatedRoute.snapshot.paramMap.get('initials');

    if (this.initials == null) return;
    this.trackingService.TrackSearch(
      TRACKING_SEARCH_TYPE.INITALS,
      this.initials
    );
  }

  ngOnInit(): void {
    // if (this.initials == null) this.initials = 'AAA'; // default
    if (this.initials == null) {
      this.getFeatured();
      return;
    }
    this.getSearchResults();
  }

  public getFeatured(): void {
    this.searchService.featuredInitials((res) => {
      this.featuredInitials = res;
    });
  }

  public UpdateInitialsInput($event: string): void {
    this.initials = $event.toUpperCase();
    if (this.initials == null || this.initials == '') return;
    this.location.go(`/initials-search/${this.initials}`);
  }

  public doInlineSearch(): void {
    this.getSearchResults();
  }

  private getSearchResults(): void {
    this.dnf_searching = true;
    this.dlf_searching = true;
    this.current_searching = true;
    this.prefix_searching = true;
    this.suffix_searching = true;
    this.initialResults = new InitialSearchResults();

    this.searchService.searchInitials(
      this.initials,
      'current',
      (res: InitialSearchResults) => {
        this.current_searching = false;
        res.current.map((r: Registration) => (r.available = r.price >= 0));
        this.initialResults.current = res.current;
        this.c_resultsChange.emit(new SearchReqResponse('c_id', res.current));
      }
    );
    this.searchService.searchInitials(
      this.initials,
      'prefix',
      (res: InitialSearchResults) => {
        this.prefix_searching = false;
        res.prefix.map((r: Registration) => (r.available = r.price >= 0));
        this.initialResults.prefix = res.prefix;
        this.p_resultsChange.emit(new SearchReqResponse('c_id', res.prefix));
      }
    );
    this.searchService.searchInitials(
      this.initials,
      'dateless_number_first',
      (res: InitialSearchResults) => {
        this.dnf_searching = false;
        res.dateless_number_first.map(
          (r: Registration) => (r.available = r.price >= 0)
        );
        this.initialResults.dateless_number_first = res.dateless_number_first;
        this.dnf_resultsChange.emit(
          new SearchReqResponse('dnf_id', res.dateless_number_first)
        );
      }
    );
    this.searchService.searchInitials(
      this.initials,
      'dateless_letter_first',
      (res: InitialSearchResults) => {
        this.dlf_searching = false;
        res.dateless_letter_first.map(
          (r: Registration) => (r.available = r.price >= 0)
        );
        this.initialResults.dateless_letter_first = res.dateless_letter_first;
        this.dlf_resultsChange.emit(
          new SearchReqResponse('dlf_id', res.dateless_letter_first)
        );
      }
    );
    this.searchService.searchInitials(
      this.initials,
      'suffix',
      (res: InitialSearchResults) => {
        this.suffix_searching = false;
        res.suffix.map((r: Registration) => (r.available = r.price >= 0));
        this.initialResults.suffix = res.suffix;
        this.s_resultsChange.emit(new SearchReqResponse('s_id', res.suffix));
      }
    );
  }
}
