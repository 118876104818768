<div
  class="component-container"
  *ngIf="conversation; else noConversation"
  [ngStyle]="{ 'max-height': windowHeight + 'px' }"
>
  <div class="header">
    <h1 class="title">{{ conversation.name }}</h1>
    <div>
      <button mat-icon-button (click)="handleCloseConvoClick()">
        <mat-icon class="text-white">close</mat-icon>
      </button>
    </div>
  </div>
  <div
    class="messages-container"
    #scrollContainer
    [scrollTop]="scrollContainer.scrollHeight"
    *ngIf="!loading; else loadingSpinner"
  >
    <ng-container *ngFor="let convoMessage of conversationMessages">
      <ng-container *ngIf="convoMessage.noSender">
        <span class="d-block m-auto text-center timestamp">{{
          convoMessage.message
        }}</span>
      </ng-container>
      <ng-container *ngIf="!convoMessage.noSender">
        <div class="message" [ngClass]="{ isSender: convoMessage.userSent }">
          <div class="content">
            <div class="{{ convoMessage.userSent ? 'text-white' : 'text-dark' }}" [innerHtml]="convoMessage.message"></div>
          </div>
          <span class="timestamp">{{
            convoMessage.timeStamp | date : "short"
          }}</span>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="input-container">
    <div class="message-input">
      <input type="text" [(ngModel)]="newMessage.message" placeholder="Aa" />
    </div>
    <div class="send-button">
      <button [disabled]="!isValidMessage()" (click)="handleSendMessageClick()">
        SEND
      </button>
    </div>
  </div>
</div>
<ng-template #noConversation>
  <div class="centered-container">
    <h2 style="margin: 0">No conversation selected.</h2>
  </div>
</ng-template>
<ng-template #loadingSpinner>
  <div class="full spinner-loader">
    <div>
      <mat-progress-spinner
        class="spinner cyan"
        mode="indeterminate"
        [color]="'#327b8d'"
        [diameter]="60"
      ></mat-progress-spinner>
    </div>
    <span>Loading Messages...</span>
  </div>
</ng-template>
