<div class="page">
  <div class="banner">
    <div class="background">
      <div class="row" *ngFor="let i of [].constructor(10)">
        <div *ngFor="let i of [].constructor(20)" class="background-text">
          PLATE<span>X</span>
        </div>
      </div>
    </div>
    <div class="banner-content">
      <div class="banner-heading">
        <h1>Private Autions</h1>
      </div>
      <div class="banner-action">
        <pla-router-button [route]="'/create-auction'"
          >CREATE LISTING</pla-router-button
        >
      </div>
    </div>
  </div>

  <div class="section page-width"></div>

  <div class="section page-width">
    <h2>Why sell on Platex?</h2>
    <p>
      Listing your private registration on platex will give you the best chance
      to sell using our revolutionary searching algorithm. Our website ensures
      visitors looking for a custom registration like yours will be targeted
      correctly giving your item the visibility it deserves.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus at massa
      risus. Aliquam erat volutpat. Suspendisse suscipit risus erat. Donec id
      mattis erat, a consectetur lorem. Duis fermentum nisl pulvinar pharetra
      laoreet. Etiam luctus, lectus sed congue gravida, diam lorem malesuada
      velit, in tincidunt ex dolor at est. Sed faucibus velit metus, vel mattis
      libero suscipit vel.
    </p>
    <p>
      Sed id massa ut lorem tempor bibendum. Aliquam tincidunt at dolor sed
      aliquam. Proin varius est vitae velit ultrices, ut posuere purus
      vestibulum. Nam sed turpis id dui faucibus ullamcorper venenatis non sem.
      Maecenas vulputate, elit ut volutpat feugiat, nisl mauris dictum nisi, sed
      sodales nulla justo non orci. Nulla eu ante convallis, consequat arcu in,
      vestibulum orci. Etiam sit amet justo eget ligula sagittis fermentum.
      Aliquam in ligula non justo dignissim sollicitudin. Morbi et nulla
      convallis, egestas magna sit amet, vestibulum odio.
    </p>
  </div>
</div>
