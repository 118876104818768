import { PlateViewerConfig } from '../models/plateViewerConfig';

export const PORSCHE_GT3_BANNER = {
  id: 199,
  url: 'https://cdn.platex.co.uk/large/porsche_gt3.png',
};
export const DEFAULT_IMAGE = {
  id: 100,
  url: 'https://cdn.platex.co.uk/large/DEFAULT_IMAGE.png',
};

export const BANNER_IMAGES = [DEFAULT_IMAGE, PORSCHE_GT3_BANNER];

export const DEFAULT_IMAGE_ID = 100;

const stored_configs: PlateViewerConfig[] = [
  new PlateViewerConfig({
    carName: 'Lamborghini Huracan Evo',
    config: JSON.stringify({
      name: 'NEW-CONFIG',
      config: {
        textInfo: {
          color: '#1b1616',
          scale: {
            x: 0.1355,
            y: 0.1355,
            z: 0.1355,
          },
          position: {
            x: 21.955318,
            y: -5.440256,
            z: 0,
          },
          rotation: {
            _x: -0.078016214,
            _y: 0.38501963,
            _z: -0.013439035,
            _order: 'XYZ',
          },
        },
        imageInfo: {
          path: 'https://cdn.platex.co.uk/plate-viewer/EVO_reduced.png',
          _width: 1920,
          _height: 1200,
        },
      },
    }),
    groupId: '265',
    id: 265,
    imageName: '6eade87d-ba0c-4fa0-9685-e67a97766060',
  }),
  new PlateViewerConfig({
    id: 369,
    carName: 'Audi R8 V10 RWD',
    config:
      '{"name": "NEW-CONFIG", "config": {"textInfo": {"color": "#0d0c0c", "scale": {"x": 0.098, "y": 0.098, "z": 0.098}, "position": {"x": 17.680832, "y": -11.113666, "z": 0.0}, "rotation": {"_x": -0.059515726, "_y": 0.4654793, "_z": 0.009424778, "_order": "XYZ"}}, "imageInfo": {"path": "https://cdn.platex.co.uk/plate-viewer/R8_Reduced.png", "_width": 1920.0, "_height": 1200.0}}}',
    imageName: '6cd3f1c4-f4fa-40da-b306-04e8a117bd7a',
    groupId: 'reduced',
  }),
  new PlateViewerConfig({
    id: 368,
    carName: 'Jaguar F-Type R',
    config:
      '{"name": "NEW-CONFIG", "config": {"textInfo": {"color": "#110f0f", "scale": {"x": 0.09, "y": 0.09, "z": 0.09}, "position": {"x": 13.1149025, "y": -17.02023, "z": 0.0}, "rotation": {"_x": 0.0, "_y": 0.40561453, "_z": 0.0, "_order": "XYZ"}}, "imageInfo": {"path": "https://cdn.platex.co.uk/plate-viewer/FTYPE_Reduced.png", "_width": 1920.0, "_height": 1200.0}}}',
    imageName: '41526600-5b16-490e-ae62-70500d140513',
    groupId: 'reduced',
  }),
  new PlateViewerConfig({
    id: 300,
    carName: 'Mini Cooper S',
    config:
      '{"name": "NEW-CONFIG", "config": {"textInfo": {"color": "#100f0f", "scale": {"x": 0.138, "y": 0.138, "z": 0.138}, "position": {"x": 19.818075, "y": -9.947897, "z": 0.0}, "rotation": {"_x": -0.12548918, "_y": 0.38885936, "_z": -0.03769911, "_order": "XYZ"}}, "imageInfo": {"path": "https://cdn.platex.co.uk/plate-viewer/JCW_reduced.png", "_width": 1920.0, "_height": 1200.0}}}',
    imageName: '2ac16ca7-7810-439c-8710-90a6a682c32a',
    groupId: 'reduced_cooper',
  }),
];

// QUICKLOAD CONFIG - PLATE VIEWER
const configId = () => {
  const randomId = Math.floor(Math.random() * stored_configs.length);
  return randomId;
};
export const quick_config: PlateViewerConfig = stored_configs[configId()];

//"https://cdn.platex.co.uk/plate-viewer/NoPath---Copy-(16).jpg"

export const SITE_BANNER_IMAGES = {
  ASTON_DB11:
    'https://cdn.platex.co.uk/large/S34RCH_ASTON_DB11.png',
  FERRARI_F40:
    'https://cdn.platex.co.uk/large/S3LLS_FERRARI_F40.png',
  FERRARI_275:
    'https://cdn.platex.co.uk/large/8UYS_FERRARI_275.png',
  FERRARI_275_MB:
    'https://cdn.platex.co.uk/banners/8UYS_FERRARI_275_BANNER_MB.png',
  RR_DEFENDER:
    'https://cdn.platex.co.uk/large/DE51GNS_RANGEROVER_DEFENDER.png',
  RR_DEFENDER_MB:
    'https://cdn.platex.co.uk/banners/DE51GNS_RANGEROVER_DEFENDER_BANNER_MB.png',
  LAMBORGHINI_DIABLO:
    'https://cdn.platex.co.uk/large/L5_LAMBORGHINI_DIABLO.png',
  LAMBORGHINI_DIABLO_MB:
    'https://cdn.platex.co.uk/banners/L5_LAMBORGHINI_DIABLO_BANNER_MB.png',
  PORSCHE_TAYCAN:
    'https://cdn.platex.co.uk/large/PAY135S_PORSCHE_TAYCAN.png',
  MINI_JCW:
    'https://cdn.platex.co.uk/large/HO72ODS_MINI_JCW.png',
  BMW_M2:
    'https://cdn.platex.co.uk/large/8UYS_BMW_M2.png',
  PORSCHE_GT3:
    'https://cdn.platex.co.uk/large/AUC710N_PORSCHE_GT3.png',
  BUGATTI_STREET:
    'https://cdn.platex.co.uk/large/BUGATTI_CHIRON_STREET.jpg',
  BUGATTI_TRACK:
    'https://cdn.platex.co.uk/large/BUGATTI_CHIRON_TRACK.jpg',
  //
  _BUGGATI:
    'https://cdn.platex.co.uk/large/BUGATTI_IMAGE.jpg',
  _GT3RS: 'https://cdn.platex.co.uk/large/porsche%20gt3%20rs.jpg',
  _FERRARI:
    'https://cdn.platex.co.uk/large/FERRARI_TDF.jpg',
  COUNTACH:
    'https://cdn.platex.co.uk/large/Sell-Countach.png',
  AVENTADOR_S:
    'https://cdn.platex.co.uk/large/AVENTADOR_S.png',
  PORSCHE_918:
    'https://cdn.platex.co.uk/large/PORSCHE_918.png',

  AVENTADOR_S_FOOTER:
    'https://cdn.platex.co.uk/pages/AVS_FOOTER.png',
  SELLING_F40:
    'https://cdn.platex.co.uk/pages/sell-landing/FERRARI_S3LLS.png',
  PORSCHE_918_S:
    'https://cdn.platex.co.uk/pages/blog-landing/PORSCHE_918_SPYDER.png',
  FERRARI_812:
    'https://cdn.platex.co.uk/pages/name-search/812_FERRARI.png',
  DIABLO_GT: 'https://cdn.platex.co.uk/pages/compare/DIABLO_GT.png',

  LANDING_918: 'https://cdn.platex.co.uk/pages/landing/PORSCHE_918_v2.png',
  LANDING_918_MB: 'https://cdn.platex.co.uk/pages/landing/PORSCHE_918_MB_v2.png',
  SELL_992_TURBO: 'https://cdn.platex.co.uk/pages/landing/992_TURBO_v2.png',
  SELL_992_TURBO_MB: 'https://cdn.platex.co.uk/pages/landing/992_TURBO_MB_v4.png',

  CURATED_MURCI: 'https://cdn.platex.co.uk/pages/new-release/CURATED_MURCI.png',
  CURATED_MURCI_MB: 'https://cdn.platex.co.uk/pages/new-release/CURATED_MURCI_MB.png',
  ATTACKS_P1: 'https://cdn.platex.co.uk/pages/new-release/ATTACKS_P1.png',
  ATTACKS_P1_MB: 'https://cdn.platex.co.uk/pages/new-release/ATTACKS_P1_MB.png',
  BERSERK_AMG: 'https://cdn.platex.co.uk/pages/new-release/BERSERK_AMG.png',
  BERSERK_AMG_MB: 'https://cdn.platex.co.uk/pages/new-release/BERSERK_AMG_MB.png',
  JAMES_RR: 'https://cdn.platex.co.uk/pages/name-search/JAMES_RR.png',
  JAMES_RR_MB: 'https://cdn.platex.co.uk/banners/JAMES_RR_MB.png',

  SEARCH_918_DT: 'https://cdn.platex.co.uk/banners/SEARCH_PORSCHE_918_DT.png',
  SEARCH_918_MB: 'https://cdn.platex.co.uk/banners/SEARCH_PORSCHE_918_MB.png',
  SELL_TURBO_DT: 'https://cdn.platex.co.uk/banners/SELL_TURBO_DT.png',
  SELL_TURBO_MB: 'https://cdn.platex.co.uk/banners/SELL_TURBO_MB.png',
  HATE_TAX_DIABLO_DT: 'https://cdn.platex.co.uk/banners/HATE_TAX_DIABLO_DT.png',
  HATE_TAX_DIABLO_MB: 'https://cdn.platex.co.uk/banners/HATE_TAX_DIABLO_MB.png',

  SUB_200_DT: 'https://cdn.platex.co.uk/banners/SUB_200_FIAT_500_DT.png',
  SUB_200_MB: 'https://cdn.platex.co.uk/banners/SUB_200_FIAT_500_MB.png',
  NUM_1_DT: 'https://cdn.platex.co.uk/banners/NUM_1_DBS_DT.png',
  NUM_1_MB: 'https://cdn.platex.co.uk/banners/NUM_1_DBS_MB.png',

  DESIGNS_Z4: 'https://cdn.platex.co.uk/pages/new-release/DESIGNS_Z4.png',
  GEORGES_CGT: 'https://cdn.platex.co.uk/pages/new-release/GEORGES_CGT.png',
  RUSRELL_RS3: 'https://cdn.platex.co.uk/pages/new-release/RUSRELL_RS3.png',
  WHOEVER_CYBERTRUCK: 'https://cdn.platex.co.uk/pages/new-release/WHOEVER_CYBERTRUCK.png',
  ARSENAL_ALPINE: 'https://cdn.platex.co.uk/pages/new-release/ARSENAL_ALPINE.png',
  SLOANES_BENTLEY: 'https://cdn.platex.co.uk/pages/new-release/SLOANES_BENTLEY.png',
  LASANGE_FERRARI: 'https://cdn.platex.co.uk/pages/new-release/LASANGE_FERRARI.png',
  GHOSTED_BMW: 'https://cdn.platex.co.uk/pages/new-release/GHOSTED_BMW.png',
  MASSAGE_FIAT500: 'https://cdn.platex.co.uk/pages/new-release/MASSAGE_FIAT500.png',
  HASGAME_URUS: 'https://cdn.platex.co.uk/pages/new-release/HASGAME_URUS.png',
  BLOGGER_MINI: 'https://cdn.platex.co.uk/pages/new-release/BLOGGER_MINI.png',
  SHOTGUN_DEFENDER: 'https://cdn.platex.co.uk/pages/new-release/SHOTGUN_DEFENDER.png',
  FASTEST_LAFERRARI: 'https://cdn.platex.co.uk/pages/new-release/FASTEST_LAFERRARI.png',
  PROBLEM_6X6: 'https://cdn.platex.co.uk/pages/new-release/PROBLEM_6X6.png',
  HUSBAND_I8: 'https://cdn.platex.co.uk/pages/new-release/HUSBAND_I8.png',
  VLOGGER_DEFENDER: 'https://cdn.platex.co.uk/pages/new-release/VLOGGER_DEFENDER.png',
  BELOVED_R8: 'https://cdn.platex.co.uk/pages/new-release/BELOVED_R8.png',
  UNGODLY_AMG: 'https://cdn.platex.co.uk/pages/new-release/UNGODLY_AMG.png',
  BALLERS_URUS: 'https://cdn.platex.co.uk/pages/new-release/BALLERS_URUS.png',
  EMIRATE_ROLLS: 'https://cdn.platex.co.uk/pages/new-release/EMIRATE_ROLLS.png',
  FRIENDS_MINI: 'https://cdn.platex.co.uk/pages/new-release/FRIENDS_MINI.png',
  GELATOS_FIAT500: 'https://cdn.platex.co.uk/pages/new-release/GELATOS_FIAT500.png',
  BRISTOL_I8: 'https://cdn.platex.co.uk/pages/new-release/BRISTOL_I8.png',
  FLIGHTS_PORSCHE: 'https://cdn.platex.co.uk/pages/new-release/FLIGHTS_PORSCHE.png',
};