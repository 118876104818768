import { Component, Input, OnInit } from '@angular/core';
import { PlateRatingResponse } from 'src/app/models/plateRatingResponse';
import { Registration } from 'src/app/models/registration';
import { RegistrationService } from 'src/app/services/registration-service';

@Component({
  selector: 'pla-inline-plate-rank',
  templateUrl: './inline-plate-rank.component.html',
  styleUrls: ['./inline-plate-rank.component.scss']
})
export class InlinePlateRankComponent implements OnInit {

  @Input() public registration: Registration;

  constructor(private registrationService: RegistrationService) { }

  ngOnInit(): void {
  }

  public VotedUp(): boolean {
    return this.registration.userRating === true;
  }

  public VotedDown(): boolean {
    return this.registration.userRating === false;
  }

  public UnVoted(): boolean {
    return this.registration.userRating === undefined;
  }

  public VoteUp(): void { }
  public VoteDown(): void { }
  public VoteCycle(): void {
    console.log(this.registration);

    if (this.registration.userRating === undefined) {
      this.registration.userRating = true;
    } else if (this.registration.userRating === true) {
      this.registration.userRating = false;
    } else {
      this.registration.userRating = undefined;
    }

    this.UpdateRank();
  }

  private UpdateRank(): void {
    const term = this.registration.search || this.registration.term;
    if (this.registration.userRating === undefined) this.registrationService.removePlateRating(this.registration.registered, term).subscribe(() => {
      console.log('removed rating')
    });
    else this.registrationService.setPlateRating(this.registration.registration, this.registration.userRating, term).subscribe((_: PlateRatingResponse) => {
      console.log(_);
    });
  }

}
