import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Data,
  Params,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

declare global {
  interface Window {
    gtag: any;
  }
}

@Injectable({ providedIn: 'root' })
export class RouteDataService {
  constructor(
    private router: Router,
    private titleService: Title,
    private metaService: Meta
  ) {}

  get(): void {
    const data = this.getRouteSnapshot();
    var routeData = { data: data.data, params: data.params };

    var titleToSet = 'PlateX - the UKs Private Plate Portal'; // base
    var description =
      'Find your perfect private number plate from 35 dealers, the DVLA and private sellers! Free price comparison on 65 million personalised plates.';
    if (routeData.data['title'] != undefined)
      titleToSet = this.constructString(routeData.data['title'], routeData);

    if (routeData.data['description'] != undefined)
      description = this.constructString(
        routeData.data['description'],
        routeData
      );

    window.gtag('event', 'page_view', {
      page_title: titleToSet,
    });
    this.titleService.setTitle(titleToSet);
    this.metaService.addTags([
      { name: 'description', content: description },
      { name: 'og:description', content: description },
    ]);

    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({
      name: 'og:description',
      content: description,
    });
  }

  private constructString(
    text: string,
    routeInfo: { data: Data; params: Params }
  ): string {
    const r = new RegExp('(?<={)(.*?)(?=})');
    const regexResults = r.exec(text);

    var builtText = text;
    if (regexResults != null) {
      regexResults.forEach((key: string) => {
        builtText = builtText.replace(
          `{${key}}`,
          (routeInfo.params[key] as string).toUpperCase()
        );
      });
    }

    return builtText;
  }

  private getRouteSnapshot(): ActivatedRouteSnapshot {
    let route: ActivatedRoute = this.router.routerState.root;
    while (route!.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot;
  }
}
