<div class="glossary-container">
  <h1 class="mb-2">Glossary</h1>
  <!-- SEARCH -->
  <div class="glossary-search">
    <mat-icon>search</mat-icon>
    <input
      [(ngModel)]="searchTerm"
      (ngModelChange)="filter()"
      placeholder="Search for a term (e.g. Dateless)"
    />
    <mat-icon class="clear" (click)="clearSearch()">clear</mat-icon>
  </div>
  <div class="glossary-content">
    <!-- LEFT TABS -->
    <div class="glossary-letter-container">
      <div class="glossary-letters">
        <ng-container *ngFor="let glosLetter of glossaryData">
          <div
            class="letter"
            [ngClass]="{ active: glosLetter.hasTerms }"
            (click)="setSearch(glosLetter)"
          >
            {{ glosLetter.letter | uppercase }}
          </div>
        </ng-container>
      </div>
    </div>

    <!-- MAIN CONTENT -->
    <div class="glossary-items">
      <div class="glossary-innner">
        <ng-container *ngFor="let glosLetter of glossaryData">
          <div
            *ngIf="glosLetter.hasTerms"
            class="letter-section pt-5"
            [ngClass]="{ active: glosLetter.hasTerms }"
          >
            <div class="letter">
              {{ glosLetter.letter | uppercase }}
            </div>

            <ng-container *ngFor="let term of glosLetter.terms">
              <div class="definition mb-4">
                <p class="term">{{ term.term }}</p>
                <p class="def">{{ term.definition }}</p>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
