<div class="page">
    <pla-main-nav-chips></pla-main-nav-chips>
    <div class="content-container extended">
        <div class="ps-5 side-container mb-3">
            <div class="pt-0 position-relative">
                <pla-side-nav></pla-side-nav>
            </div>

            <div class="bg-light py-3">
                <pla-featured-plates [featureType]="raFeatured" [plateSize]="'smaller'" [priceFontSize]="16"
                    [maxCount]="10" [sortBy]="'price'" [plateClass]="'flat shaded'"
                    [showIndex]="false"></pla-featured-plates>
            </div>
        </div>
        <div class="center-content-container position-relative">
            <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
                <pla-banner-image [carBanner]="'SUB_200_DT'" [mobileCarBanner]="'SUB_200_MB'" [rawImage]="true"
                    [backgroundImage]="true" [backgroundClass]="'compare-mobile-background'">
                    <div class="compare-banner-height">
                        <div class="d-flex d-md-flex align-items-start justify-content-between flex-column">
                            <div class="p-4 p-md-4">
                                <p class="lh-sm fw-bold fs-md-28 fs-mb-24 text-white">PLATES UNDER £200</p>
                            </div>
                        </div>
                    </div>
                </pla-banner-image>

                <div class="mt-4">
                    <p class="px-4 text-center fs-md-16 fs-mb-14 mb-0 text-center mx-auto lh-sm">Some text about how
                        cheap these plates are</p>

                    <div class="s-spacing"></div>
                </div>
            </div>

            <div
                class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-2 px-md-1 py-0 me-0 me-md-2">
                <!-- RESULTS -->
                <div class="pla_card no-card mobile spacing px-2">
                    <div class="my-3 mx-auto w-100">
                        <div class="d-flex align-items-center justify-content-end py-1 px-1 overflow-auto">
                            <select class="mx-0 w-100 d-block"
                                style="max-width: 200px; border-color: #214953 !important" [(ngModel)]="termSorting"
                                (change)="TermSortChange()">
                                <option [ngValue]="'A-Z'" selected>A-Z</option>
                                <option [ngValue]="'Z-A'">Z-A</option>
                                <option [ngValue]="'HIGH-TO-LOW'">Price High to Low</option>
                                <option [ngValue]="'LOW-TO-HIGH'">Price Low to High</option>
                            </select>
                        </div>
                    </div>

                    <div>
                        <pla-search-result-table [showCount]="true" [headerText]="'SUB £200 PLATES'"
                            [results]="results.data" [resultChange]="results.updated" [searching]="results.loading"
                            [showFilter]="false" [showSort]="false" [showToggle]="false" [showFullSeries]="true"
                            [paginationPageSize]="100"></pla-search-result-table>
                    </div>

                </div>
            </div>

            <div class="s-spacing"></div>

            <div
                class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-0 px-md-1 py-0 me-0">
                <div class="px-0 px-md-2">
                    <div class="pb-5 pt-5">
                        <p class="text-center text-md-start fs-md-26 fs-mb-22">
                            Frequently Asked Questions
                        </p>
                    </div>
                    <div class="w-100 px-3 px-md-0 pb-5">
                        <span>coming soon</span>
                        <!-- <pla-faq [faq]="faq"></pla-faq> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>