import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Registration } from '../../models/registration';
import {
  ExtendedSearchReqResponse,
  SearchReqResponse,
  SearchService,
} from 'src/app/services/search-service';
import { RegistrationService } from 'src/app/services/registration-service';
import { SynonymResult } from 'src/app/models/synonymResult';
import { BYOTermResponse } from 'src/app/models/byoTermResponse';
import { UserService } from 'src/app/services/user-service';
import { LoggerService } from 'src/app/services/logger-service';
import { AltSearchOption } from 'src/app/models/altSearchOption';
import { QuickSignupService } from 'src/app/services/quick-signup-service';
import { SessionService } from 'src/app/services/session-service';
import {
  TrackingService,
  TRACKING_SEARCH_TYPE,
} from 'src/app/services/tracking-service';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import { PlatexUserAlert } from 'src/app/models/platexUserAlert';
import { PAGE_PLACEMENTS, PagePlacement } from 'src/app/components/page-placement/page-placement.component';
import { SidebarFilter, SidebarFilterConfig, SidebarFilterGroup } from 'src/app/components/side-bar-filters/side-bar-filters.component';
import { DropdownConfig, DropdownOption } from 'src/app/components/custom-dropdown/custom-dropdown.component';
import { environment } from 'src/environments/environment';
import { StickyConfig, StickyLink, StickyLinkGroup } from 'src/app/components/sticky-tabs/sticky-tabs.component';
import { ViewportScroller } from '@angular/common';

export interface SearchFilters {
  sortBy: string,
  up_to_350: boolean;
  from_350_to_750: boolean;
  over_750: boolean;
  new_issue: boolean;
  new_release: boolean;
  resale: boolean;
  auction: boolean;
  private_listing: boolean;
  current: boolean;
  prefix: boolean;
  suffix: boolean;
  dateless: boolean;
}

@Component({
  selector: 'pla-search-results-page',
  templateUrl: './search-results-page.component.html',
  styleUrls: ['./search-results-page.component.scss'],
})
export class SearchResultsPageComponent implements OnInit, AfterViewInit {
  public searchTrigger: EventEmitter<void> = new EventEmitter<void>();
  public newlyListedResults: SearchReqResponse;
  public npFeatured = new FeatureType(
    'Recently Added',
    'recently-added'
  );

  public currentFilters: any = {};
  public filterChange: EventEmitter<SearchFilters> = new EventEmitter<SearchFilters>();
  public sortDropdownConfig: DropdownConfig = new DropdownConfig('Sort By', 'swap_vert', [
    new DropdownOption('most-relevant', 'Most Relevant'),
    new DropdownOption('price-ascending', 'Price Low to High'),
    new DropdownOption('price-descending', 'Price High to Low')
  ]);
  public searchFilters: SidebarFilterConfig<SearchFilters> =
    new SidebarFilterConfig<SearchFilters>(
      [
        // new SidebarFilterGroup('Vehicle Suitability', [
        //   new SidebarFilter('select', '', 'vehicleSuitability', {
        //     selectConfig: new DropdownConfig('Age Check', 'filter_alt', [
        //       new DropdownOption('age_check', 'Age Check')
        //     ])
        //   })
        // ]),
        new SidebarFilterGroup('Sort', [
          new SidebarFilter('select', 'Sort By', 'sortBy', {
            selectConfig: this.sortDropdownConfig
          }),
        ], false),
        new SidebarFilterGroup('Price', [
          new SidebarFilter('checkbox', 'Up To £350', 'up_to_350'),
          new SidebarFilter('checkbox', '£350 to £750', 'from_350_to_750'),
          new SidebarFilter('checkbox', 'Over £750', 'over_750'),
        ]),
        new SidebarFilterGroup('Sale Type', [
          new SidebarFilter('checkbox', 'New Issue', 'new_issue'),
          new SidebarFilter('checkbox', 'Resale', 'resale'),
          // new SidebarFilter('checkbox', 'Auction', 'auction'),
          // new SidebarFilter('checkbox', 'Private Listing', 'private_listing'),
        ]),
        new SidebarFilterGroup('Plate Type', [
          new SidebarFilter('checkbox', 'Current', 'current'),
          new SidebarFilter('checkbox', 'Prefix', 'prefix'),
          new SidebarFilter('checkbox', 'Suffix', 'suffix'),
          new SidebarFilter('checkbox', 'Dateless', 'dateless'),
        ]),
      ],
      this.filterChange
    );
  public ownSimilarPlacement: PagePlacement = PAGE_PLACEMENTS.OWN_SIMILAR_FREE_3_MONTHS();
  public howDidWeDo: PagePlacement = PAGE_PLACEMENTS.HOW_DID_WE_DO();

  public results: SearchReqResponse;
  public perfectResults: SearchReqResponse;
  public wildcardResults: SearchReqResponse;
  public synonymResults: SynonymResult[];
  public perfectSearching: boolean;
  public superSearching: boolean;
  public superSearchComplete: EventEmitter<void> = new EventEmitter<void>();
  public wildcardSearching: boolean;
  public byoTerm: BYOTermResponse;
  public alternativeOptions: AltSearchOption[];
  private allChips: StickyLink[] = [
    new StickyLink('Close Matches', 'close-matches', true),
    new StickyLink('Similar', 'similar', false),
    new StickyLink('Current Lists', 'current-lists', false),
    new StickyLink('Prefix Lists', 'prefix-lists', false),
    new StickyLink('Q&As', 'faq', false),
  ]
  public stickyLinkConfig: StickyConfig = new StickyConfig(
    [new StickyLinkGroup(this.allChips)],
    new EventEmitter<void>()
  );

  public searchCriteria: string;
  public routeFrom: string;
  public newlyListedResultChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public resultChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public perfectResultChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();

  public currentResults: SearchReqResponse[];
  public prefixResults: SearchReqResponse[];

  public wildcardResultChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();

  public bannerRegistration: string = 'S34 RCH';
  public bannerUpdate: EventEmitter<string> = new EventEmitter<string>();
  public fetchedAlerts: boolean = false;
  public shownPopup: boolean = false;

  constructor(
    private searchService: SearchService,
    private userService: UserService,
    private registrationService: RegistrationService,
    private route: ActivatedRoute,
    private loggerService: LoggerService,
    private router: Router,
    private sessionService: SessionService,
    private quickSignupService: QuickSignupService,
    private trackingService: TrackingService,
    private viewportScroller: ViewportScroller
  ) {
    this.searchCriteria = this.route.snapshot.params.searchCriteria;
    this.routeFrom = this.route.snapshot.queryParams['routeFrom'];
    this.trackingService.TrackSearch(
      TRACKING_SEARCH_TYPE.MAIN,
      this.searchCriteria
    );
    this.filterChange.subscribe((_: SearchFilters) => this.currentFilters = _);
  }

  ngOnInit(): void {
    this.userService.getPlatexUserAlerts(() => {
      this.fetchedAlerts = true;
    });

    if (this.superSearching || this.perfectSearching || this.wildcardSearching) {
      return;
    }

    this.search();
    this.searchAltTerms();
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 1);
    setTimeout(() => {
      window.scrollTo(0, 1);
    }, 100);
  }

  public SortChange(e: DropdownOption, key: string): void {
    console.log(this.currentFilters);
    this.currentFilters[key] = e.value;
    this.filterChange.emit(this.currentFilters);
  }

  public generateSynonymHeaderText(synonymResult: SynonymResult): string {
    return `Similar search results to ${this.searchCriteria.toUpperCase()}`;
    return `Alternative search results for ${this.searchCriteria.toUpperCase()} - "${synonymResult.synonym.toUpperCase()}"`;
  }

  public routeToNewSearch(term: AltSearchOption): void {
    this.router.navigate([`/search-results/${term.term}`], {
      queryParams: { routeFrom: this.searchCriteria.toUpperCase() },
    });
  }

  public hasAlert(): boolean {
    return this.userService.hasAlert(this.searchCriteria);
  }

  public addQuickAlert(): void {
    if (!this.searchCriteria || this.searchCriteria == '') return;
    var alert = new PlatexUserAlert();
    alert.term = this.searchCriteria;
    this.userService.addAlert(alert, (_alerts: PlatexUserAlert[]) => {
      // added
    });
  }

  public search() {
    this.perfectSearching = true;
    this.superSearching = true;
    this.wildcardSearching = true;
    this.userService.getUserData(() => {
      setTimeout(() => {
        console.log('scroll down a smidge');
        this.viewportScroller.scrollToAnchor('recent-searches');
      }, 500);
      this.searchRaw(() => {
        console.log('search raw complete');
        setTimeout(() => {
          this.superSearchComplete.emit();
          if (!this.sessionService.isLoggedIn() && !this.shownPopup) {
            this.shownPopup = true;
            this.quickSignupService.signupCapture();
          }

          this.searchComplete();
        }, 50);
      });
      this.searchSynonym();
      this.searchPerfect(() => {
        this.searchComplete();
        // this.getBYO();
      });
      this.searchExtended();
    });
  }

  private searchComplete(): void {
    if (this.perfectResults == undefined || this.results == undefined) return;
    if (this.searchCriteria) {
      var resultCount = this.perfectResults.registrations.length + this.results.registrations.length
      this.userService.addSearchToHistory(this.searchCriteria, `${resultCount} Results`);
    }
  }

  private searchAltTerms(): void {
    return; // TODO: RE-ADD
    this.searchService.getAltSearchTerms(
      this.searchCriteria.toUpperCase(),
      (_: AltSearchOption[]) => {
        if (
          this.routeFrom &&
          _.filter((__) => __.term == this.routeFrom).length == 0
        )
          _.push(
            new AltSearchOption(this.routeFrom, this.routeFrom, 'original')
          );
        if (_ != null) this.alternativeOptions = _.reverse();
      }
    );
  }

  private searchExtended(): void {

    this.stickyLinkConfig.groups[0].links.map(c => {
      if (c.anchor === 'current-lists') c.hidden = true;
      if (c.anchor === 'prefix-lists') c.hidden = true;
    });

    this.searchService.extendedSearch(this.searchCriteria, 'current', (c_req: ExtendedSearchReqResponse[]) => {
      var hasResults = false;
      this.currentResults = c_req.map(x => {
        if (x.results.length > 0) hasResults = true;
        return { id: x.version, registrations: this.registrationService.formatRegistrations(x.results) };
      });
      this.stickyLinkConfig.groups[0].links.map(c => {
        if (c.anchor === 'current-lists') c.hidden = !hasResults;
      })

      this.searchService.extendedSearch(this.searchCriteria, 'prefix', (p_req: ExtendedSearchReqResponse[]) => {
        var hasResults = false;
        this.prefixResults = p_req.map(x => {
          if (x.results.length > 0) hasResults = true;
          return { id: x.version, registrations: this.registrationService.formatRegistrations(x.results) };
        });
        this.stickyLinkConfig.groups[0].links.map(c => {
          if (c.anchor === 'prefix-lists') c.hidden = !hasResults;
        })
      })
    });
  }

  private searchPerfect(callback: () => void = () => { }): void {
    this.searchService.searchPerfect(
      this.searchCriteria,
      (res: Registration[]) => {
        try {
          this.perfectSearching = false;
          if (res != null) {
            var formattedResults =
              this.registrationService.formatRegistrations(res);
            const tempSearchReq = new SearchReqResponse(
              'perfect_id',
              formattedResults
            );
            this.perfectResultChange.emit(tempSearchReq);
            this.perfectResults = tempSearchReq;
          }
          callback();
        } catch (ex: any) {
          this.loggerService.logException(ex);
          callback();
        }
      }
    );
  }

  private getBYO(callback: () => void = () => { }): void {
    this.searchService.getBYOTerm(
      this.searchCriteria,
      (res: BYOTermResponse) => {
        try {
          this.byoTerm = res;
          callback();
        } catch (ex: any) {
          this.loggerService.logException(ex);
          callback();
        }
      }
    );
  }

  private searchSynonym(callback: () => void = () => { }): void {

    this.stickyLinkConfig.groups[0].links.map(c => {
      if (c.anchor === 'similar') c.hidden = true;
    });

    this.searchService.searchSynonym(
      this.searchCriteria,
      (res: SynonymResult[]) => {
        try {
          if (res != null) {
            res.map((_res) => {
              _res.resp.id = 'synonym_id';
              _res.resp.registrations =
                this.registrationService.formatRegistrations(
                  _res.resp.registrations
                );
            });
            if (res.length == 0) return;

            const allResults = res.flatMap((r) => r.resp.registrations);
            allResults.sort((a, b) => b.percentage - a.percentage);
            const x = new SynonymResult();
            x.synonym = this.searchCriteria;
            x.resp = new SearchReqResponse(res[0].resp.id, allResults);

            this.synonymResults = [x];
            this.stickyLinkConfig.groups[0].links.map(c => {
              if (c.anchor === 'similar') c.hidden = false;
            });
          }
          callback();
        } catch (ex: any) {
          this.loggerService.logException(ex);
          callback();
        }
      }
    );
  }

  private searchRaw(callback: () => void = () => { }): void {
    this.searchService.searchRaw(
      this.searchCriteria,
      (res: SearchReqResponse) => {
        try {
          console.log('super search complete');
          this.superSearching = false;
          res.registrations = this.registrationService.formatRegistrations(
            res.registrations
          );
          this.resultChange.emit(res);
          this.results = res;
          this.extractNewlyListedFromResults();
          callback();
        } catch (ex: any) {
          this.loggerService.logException(ex);
          callback();
        }
      }
    );
  }

  private extractNewlyListedFromResults(): void {
    //var auctionPlates: Registration[] = [];
    var newStylePlates: Registration[] = [];
    if (!this.results) return;
    this.results.registrations.forEach((reg: Registration, i: number) => {
      if (reg.style == 'current' && reg.registration.substring(2, 4) == environment.latestRelease)
        newStylePlates.push(reg);
    });

    const tempSearchReq = new SearchReqResponse('newly_listed', [
      ...newStylePlates.slice(0, 2),
    ]);
    this.newlyListedResults = tempSearchReq;
    this.newlyListedResultChange.emit(this.newlyListedResults);
  }
}
