import { Component, EventEmitter, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import {
  FaqBuilder,
  InfoDropdown,
  InfoDropdownContent,
} from 'src/app/components/faq/faq.component';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import { PlatopediaPlate } from 'src/app/models/platopediaPlate';
import { Registration } from 'src/app/models/registration';
import { RegistrationService } from 'src/app/services/registration-service';
import { SearchService } from 'src/app/services/search-service';
import {
  PlatopediaFilter,
  PlatopediaSearchRequest,
} from '../platopedia-page/platopedia-page.component';
import { UserService } from 'src/app/services/user-service';
import SwiperCore, { Navigation, Thumbs, Pagination, Swiper } from 'swiper';
import {
  StickyConfig,
  StickyLink,
  StickyLinkGroup,
} from 'src/app/components/sticky-tabs/sticky-tabs.component';

SwiperCore.use([Navigation, Thumbs, Pagination]);

@Component({
  selector: 'name-plate-page',
  templateUrl: './name-plate-page.component.html',
  styleUrls: ['./name-plate-page.component.scss'],
})
export class NamePlatePageComponent implements OnInit {
  public stickyLinkConfig: StickyConfig = new StickyConfig(
    [
      new StickyLinkGroup(
        [
          new StickyLink('Names', 'names', true),
          new StickyLink('Market Insights', 'market-information', false),
          new StickyLink('Q&As', 'faq', false),
        ])],
    new EventEmitter<void>()
  );
  public alphabet: string[] = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  public currentAlphabet = 'A';
  public searchRequest: PlatopediaSearchRequest;
  private searchResults: Registration[] = [];
  public npFeatured = new FeatureType(
    'Most Expensive Names',
    'most-expensive-names'
  );
  public hideSold: boolean = false;
  public loading: boolean;
  public saving: boolean = false;
  public notifiying: boolean = false;

  public activeFilters: string[] = ['gb'];
  private lastSearchQuery: string;

  public faq: InfoDropdown[] = [
    FaqBuilder.build('faq_1', 'Can I make a personal name number plate with my name?', [`Certainly! Creating a personalised name number plate for your name is possible in most instances. However, personalised number plates have a maximum limit of 7 characters.  Therefore, if your name is longer, you may need to abbreviate it.`, `Additionally, it's worth mentioning that all UK number plate styles must include at least one number.  The best name number plates perfectly integrate one or more numbers that closely resembles one of the letters in that name.`]),
    FaqBuilder.build('faq_2', 'What do you mean by a perfect name plate?', [`When we discuss a "perfect name plate," we do so in the context of the optimal personalised number plate achievable within the UK regulatory framework. It's important to note that all UK number plates must incorporate at least one numerical digit, making it impossible to spell a word using only letters.`, `Therefore, a perfect name plate in this context refers to a personalised number plate that includes a number that very closely resemble a letter. A great example is the private plate "J4 MES," where the numerical digit "4" seamlessly substitutes for the letter "A."`]),
    FaqBuilder.build('faq_3', 'Is there a perfect personal number plate for my name?', [`Absolutely! PlateX gives you access to number plates from the DVLA, professional dealers and private sellers giving you the greatest chance to find the perfect personalised number plate for your name.`, `Dive into our exclusive selection of name number plates, meticulously curated to showcase the perfect name plates. If you cannot find your name there, simply toggle the search settings to include sold plates.  If the ideal match still eludes you, try our Smart Search, the industry’s most powerful number plate search engine to scour the entire market for the best registrations tailored to your name.`, `With access to the entire market and advanced search capabilities, you can be sure to secure your ideal personalised number plate.`]),
    FaqBuilder.build('faq_4', 'Are name number plates expensive?', [`The Perfect name number plates are highly sought after and can command very high prices however that generally depends on the popularity of the name.  Indeed, for a few less common names, the matching perfect plate still remains as unissued stock by the DVLA, giving some lucky individuals a chance to buy their perfect name plate at a fraction of the price on the resale market.`, `In general, the cost of name plates depends on both the popularity of the name and how easy it is to recognise.`, `Combinations that most clearly represent your name will usually be the most expensive, however with over 70 million registrations for sale there are always options to suit all budgets.  Use our smart search to see your selection and then compare prices to find the best deal.`]),

    //FaqBuilder.build('faq_2', '', [``]),


    // new InfoDropdown(
    //   'faq_1',
    //   'Can I make a personal name number plate with my name?',
    //   true,
    //   [
    //     new InfoDropdownContent(
    //       null,
    //       `<p>Certainly! Creating a personalised name number plate for your name is possible in most instances. However, personalised number plates have a maximum limit of 7 characters.  Therefore, if your name is longer, you may need to abbreviate it.</p>
    //     <p>Additionally, it's worth mentioning that all UK number plate styles must include at least one number.  The best name number plates perfectly integrate one or more numbers that closely resembles one of the letters in that name.</p>`
    //     ),
    //   ]
    // ),
    // new InfoDropdown(
    //   'faq_2',
    //   'What do you mean by a perfect name plate?',
    //   false,
    //   [
    //     new InfoDropdownContent(
    //       null,
    //       `<p>When we discuss a "perfect name plate," we do so in the context of the optimal personalised number plate achievable within the UK regulatory framework. It's important to note that all UK number plates must incorporate at least one numerical digit, making it impossible to spell a word using only letters.</p>
    //     <p>Therefore, a perfect name plate in this context refers to a personalised number plate that includes a number that very closely resemble a letter. A great example is the private plate "J4 MES," where the numerical digit "4" seamlessly substitutes for the letter "A".</p>`
    //     ),
    //   ]
    // ),
    // new InfoDropdown(
    //   'faq_3',
    //   'Is there a perfect personal number plate for my name?',
    //   false,
    //   [
    //     new InfoDropdownContent(
    //       null,
    //       `<p>Absolutely! PlateX gives you access to number plates from the DVLA, professional dealers and private sellers giving you the greatest chance to find the perfect personalised number plate for your name.   </p>
    //     <p>Dive into our exclusive selection of name number plates, meticulously curated to showcase the perfect name plates. If you cannot find your name there, simply toggle the search settings to include sold plates.  If the ideal match still eludes you, try our Smart Search, the industry's most powerful number plate search engine to scour the entire market for the best registrations tailored to your name.</p>
    //     <p>With access to entire market and advanced search capabilities, you can be sure to secure your ideal personalised number plate.</p>`
    //     ),
    //   ]
    // ),
    // new InfoDropdown('faq_4', 'Are name number plates expensive?', false, [
    //   new InfoDropdownContent(
    //     null,
    //     `<p>The Perfect name number plates are highly sought after and can command very high prices however that generally depends on the popularity of the name.  Indeed, for a few less common names, the matching perfect plate still remain as unissued stock by the DVLA, giving some lucky individuals a chance to buy their perfect name plate at a fraction of the price on the resale market.</p>
    //     <p>In general, the cost of name plates depends on both the popularity of the name and how easy it is to recognise.
    //     Combinations that most clearly represent your name will usually be the most expensive, however with over 70 million registrations for sale there are always options to suit all budgets.  Use our smart search to see your selection and then compare prices to find the best deal.</p>`
    //   ),
    // ]),
  ];

  public flagFilters = [
    { iso: 'gb', filter: 'gb', name: 'UK', flag: 'great%20britain' },
    { iso: 'ae', filter: 'ae', name: 'UAE', flag: 'united%20arab%20emirates' },
    { iso: 'at', filter: 'at', name: 'Austria', flag: 'austria' },
    { iso: 'be', filter: 'be', name: 'Belgium', flag: 'belgium' },
    { iso: 'ch', filter: 'ch', name: 'Switzerland', flag: 'switzerland' },
    { iso: 'cn', filter: 'cn', name: 'China', flag: 'china' },
    { iso: 'de', filter: 'de', name: 'Germany', flag: 'germany' },
    { iso: 'dk', filter: 'dk', name: 'Denmark', flag: 'denmark' },
    { iso: 'es', filter: 'es', name: 'Spain', flag: 'spain' },
    { iso: 'fr', filter: 'fr', name: 'France', flag: 'france' },
    { iso: 'gr', filter: 'gr', name: 'Greece', flag: 'greece' },
    { iso: 'il', filter: 'il', name: 'Israel', flag: 'israel' },
    { iso: 'in', filter: 'in', name: 'India', flag: 'india' },
    { iso: 'is', filter: 'is', name: 'Iceland', flag: 'iceland' },
    { iso: 'it', filter: 'it', name: 'Italy', flag: 'italy' },
    { iso: 'jp', filter: 'jp', name: 'Japan', flag: 'japan' },
    { iso: 'kr', filter: 'kr', name: 'South Korea', flag: 'south%20korea' },
    { iso: 'kz', filter: 'kz', name: 'Kazakhstan', flag: 'kazakhstan' },
    { iso: 'lu', filter: 'lu', name: 'Luxembourg', flag: 'luxembourg' },
    { iso: 'mt', filter: 'mt', name: 'Malta', flag: 'malta' },
    { iso: 'nl', filter: 'nl', name: 'Netherlands', flag: 'netherlands' },
    { iso: 'no', filter: 'no', name: 'Norway', flag: 'norway' },
    { iso: 'pl', filter: 'pl', name: 'Poland', flag: 'poland' },
    { iso: 'pt', filter: 'pt', name: 'Portugal', flag: 'portugal' },
    { iso: 'ru', filter: 'ru', name: 'Russia', flag: 'russia' },
    { iso: 'se', filter: 'se', name: 'Sweden', flag: 'sweden' },
    { iso: 'tr', filter: 'tr', name: 'Turkey', flag: 'turkey' },
    { iso: 'ua', filter: 'ua', name: 'Ukraine', flag: 'ukraine' },
    { iso: 'vn', filter: 'vn', name: 'Vietnam', flag: 'vietnam' },
  ];

  public swiper: Swiper;
  public swiperNavigation = {
    nextEl: '.swiper-nav.swiper-next',
    prevEl: '.swiper-nav.swiper-prev',
  };
  public swiperBreakpoints = {
    1200: {
      slidesPerView: 5,
    },
    1100: {
      slidesPerView: 5,
    },
    900: {
      slidesPerView: 4,
    },
    850: {
      slidesPerView: 4,
    },
    700: {
      slidesPerView: 3,
    },
    550: {
      slidesPerView: 2,
    },
  };

  constructor(
    private searchService: SearchService,
    private registrationService: RegistrationService,
    private userService: UserService
  ) {
    this.searchRequest = new PlatopediaSearchRequest(
      'AZ',
      [new PlatopediaFilter('Names', 'name', true)],
      'A-Z',
      'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    );
    this.searchRequest.indiLetter = 'ALL';
    this.searchRequest.queryString = `?allFiltersRequired=false&filters=`;

    this.Search();
  }

  ngOnInit(): void { }

  public swiperInit(_e: any): void {
    this.swiper = _e[0];
  }

  public setAlphabet(letter: string): void {
    this.currentAlphabet = letter;
  }

  private CreateQueryString(): string {
    var queryString = this.searchRequest.queryString;
    if (this.activeFilters.length > 0)
      queryString += `${this.activeFilters.join(',')}`;
    else queryString += 'name';
    return queryString;
  }

  public Search(): void {
    const qs = this.CreateQueryString();
    if (this.lastSearchQuery != null) {
      if (this.lastSearchQuery == qs) return; // dont repeat search
    }
    this.lastSearchQuery = qs;
    this.loading = true;

    this.searchService.searchplatopedia(
      qs,
      (filters: string, _: PlatopediaPlate[]) => {
        if (filters !== this.CreateQueryString()) return;
        this.searchResults = this.registrationService.formatRegistrations(
          [..._].map((p: PlatopediaPlate) => {
            p.registration.meta_data = {
              term: p.word,
            };
            p.registration.term = p.word;
            return p.registration;
          })
        );

        console.log(this.searchResults);

        this.loading = false;
      }
    );
  }

  public filterChange(open: boolean): void {
    if (!open) this.Search();
  }

  public toggleFilter(filter: string): void {
    this.activeFilters = [filter];
    try {
      navigator.vibrate(100);
    } catch (e: any) {
      console.log(e);
    }
    this.Search();
  }

  public updateQueryString(change: MatSelectChange): void {
    this.activeFilters = [...change.value];
  }

  public FilterActive(filter: string): boolean {
    return this.activeFilters.findIndex((f) => f == filter) > -1;
  }

  public FiltersUpdated(filter: string): void {
    const filterIndex = this.activeFilters.findIndex((f) => f == filter);
    if (filterIndex === -1) this.activeFilters.push(filter);
    else this.activeFilters.splice(filterIndex, 1);

    this.Search();
  }

  public PlatesForLetter(letter: string): Registration[] {
    if (this.searchResults === undefined || this.searchResults.length === 0)
      return [];
    this.loading = true;
    const res = this.searchResults
      .filter((p) => p.term.startsWith(letter))
      .filter((p) => {
        if (this.hideSold && p.plateOwner == -1) return false;
        return true;
      })
      .sort((a, b) => a.meta_data['term'].localeCompare(b.meta_data['term']));

    this.loading = false;
    return res;
  }

  public notifyRegistration(reg: Registration): void {
    if (this.notifiying) return;
    this.notifiying = true;
    reg.notify = !reg.notify; // UI update
    this.userService.notifyRegistrationString(
      reg.registration,
      (notifs: Registration[]) => {
        var _filteredNotifies = notifs.filter(
          (f) =>
            f.registration.replace(' ', '') == reg.registration.replace(' ', '')
        );
        reg.notify = _filteredNotifies && _filteredNotifies.length > 0;
        this.notifiying = false;
      }
    );
  }

  public favouriteRegistration(reg: Registration): void {
    if (this.saving) return;
    this.saving = true;
    reg.favourite = !reg.favourite; // UI update
    this.userService.favouriteRegistrationString(
      reg.registration,
      (favs: Registration[]) => {
        var _filteredFavourites = favs.filter(
          (f) =>
            f.registration.replace(' ', '') == reg.registration.replace(' ', '')
        );
        reg.favourite = _filteredFavourites && _filteredFavourites.length > 0;
        this.saving = false;
      }
    );
  }
}
