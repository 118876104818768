<div *ngIf="registration" class="d-flex align-items-center justify-content-center gap-0 flex-column"
    style="width: 40px;" (click)="VoteCycle()">
    <mat-icon *ngIf="VotedUp()" class="mat-icon pointer i-center fs-mb-30 fs-md-35"
        style="height: 15px; color: #64AF96;">arrow_drop_up</mat-icon>

    <mat-icon *ngIf="UnVoted() || VotedDown()" class="mat-icon pointer i-center fs-mb-30 fs-md-35"
        style="height: 15px; color: #9D9D9D;">arrow_drop_up</mat-icon>
    <small [ngStyle]="{'color': UnVoted() ? '#6c757d' : VotedDown() ? '#E87676' : '#64AF96' }"
        [ngClass]="{'fw-bolder': !UnVoted(), 'fw-normal': UnVoted() }" class="my-0 text-center lh-sm">{{ UnVoted() ?
        'Vote' : VotedUp()
        ? 'Voted' : 'Voted' }}</small>
    <mat-icon *ngIf="UnVoted() || VotedUp()" class="mat-icon pointer i-center fs-mb-30 fs-md-35"
        style="height: 15px; color: #9D9D9D;">arrow_drop_down</mat-icon>

    <mat-icon *ngIf="VotedDown()" class="mat-icon pointer i-center fs-mb-30 fs-md-35"
        style="height: 15px; color: #E87676;">arrow_drop_down</mat-icon>
</div>