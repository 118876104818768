import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { QuickSignUpModalComponent } from '../components/quick-sign-up-modal/quick-sign-up-modal.component';
import { SignupCaptureModalComponent } from '../components/signup-capture-modal/signup-capture-modal.component';
import { SessionService } from './session-service';

@Injectable({ providedIn: 'root' })
export class QuickSignupService {
  private hasShownInSession = false;

  constructor(
    private sessionService: SessionService,
    private dialog: MatDialog
  ) { }

  public signupCapture(force: boolean = false): void {
    if (this.hasShownInSession && !force) return;
    this.hasShownInSession = true;
    this.dialog.open(SignupCaptureModalComponent, {
      panelClass: 'no-padding',
    });
  }

  public quickSignup(
    reason: string,
    callback: (resp: boolean) => void,
    completeCallback: () => void
  ): void {
    if (this.sessionService.isLoggedIn()) {
      callback(true);
      return; // were logged in
    }
    if (this.sessionService.requestingAccess) return; // dont open the modal multiple times
    this.sessionService.requestingAccess = true;
    var signupDialog = this.dialog.open(QuickSignUpModalComponent, {
      data: { reason: reason },
    });
    signupDialog.afterClosed().subscribe((_: boolean) => {
      this.sessionService.requestingAccess = false; // reset to ensure access is requested after close
      if (_) {
        this.sessionService.EnterQueue(() => {
          completeCallback();
        });
        callback(_);
      }
    });
  }
}
