import { Component, Input, OnInit } from '@angular/core';
import { Registration } from 'src/app/models/registration';
import { QuickSignupService } from 'src/app/services/quick-signup-service';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'pla-inline-favourite',
  templateUrl: './inline-favourite.component.html',
  styleUrls: ['./inline-favourite.component.scss']
})
export class InlineFavouriteComponent implements OnInit {

  public loading: boolean = false;
  @Input() public plate: Registration;
  @Input() public showText: boolean = true;
  @Input() public force: 'favourite' | 'notify' | 'none' = 'none';
  constructor(private signupService: QuickSignupService, private userService: UserService) { }

  ngOnInit(): void {
  }

  public handleFavouriteRegistration(): void {
    this.signupService.quickSignup(`favourite ${this.plate.formattedRegistration}`, (status: boolean) => {
      if (status) this.favouriteRegistration();
    }, () => { });
  }

  public handleNotifyRegistration(): void {
    this.signupService.quickSignup(`notify ${this.plate.formattedRegistration}`, (status: boolean) => {
      if (status) this.notifyRegistration();
    }, () => { });
  }

  public canShowFavourite(): boolean {
    if (this.force === 'notify') return false;
    if (this.force === 'favourite') return true;
    if (this.plate.available && this.plate.price > 0) return true;
    return false;
  }

  public canShowNotify(): boolean {
    if (this.force === 'favourite') return false;
    if (this.force === 'notify') return true;
    if (!this.plate.available || this.plate.price <= 0) return true;
    return false;
  }

  public notifyRegistration(): void {
    var reg = this.plate;
    reg.notify = !reg.notify; // UI update
    this.loading = true;
    this.userService.notifyRegistration(reg, (notifies: Registration[]) => {
      var _filteredNotifies = notifies.filter(
        (f) =>
          f.registration.replace(' ', '') == reg.registration.replace(' ', '')
      );
      reg.notify = _filteredNotifies && _filteredNotifies.length > 0;
      this.loading = false;
    });
  }

  public favouriteRegistration(): void {
    var reg = this.plate;
    reg.favourite = !reg.favourite; // UI update
    this.loading = true;
    this.userService.favouriteRegistration(reg, (favs: Registration[]) => {
      var _filteredFavourites = favs.filter(
        (f) =>
          f.registration.replace(' ', '') == reg.registration.replace(' ', '')
      );
      reg.favourite = _filteredFavourites && _filteredFavourites.length > 0;
      this.loading = false;
    });
  }

}
