import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Registration } from 'src/app/models/registration';
import { RegistrationService } from 'src/app/services/registration-service';
import { SessionService } from 'src/app/services/session-service';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'pla-account-favourites',
  templateUrl: './account-favourites.component.html',
  styleUrls: ['./account-favourites.component.scss'],
})
export class AccountFavouritesComponent implements OnInit {
  public favourites: Registration[] = [];
  public loading: boolean = true;

  constructor(
    private sessionService: SessionService,
    private userService: UserService,
    private router: Router,
    private registrationService: RegistrationService
  ) {
    this.loading = true;
    this.userService.favouritesUpdated.subscribe((_: Registration[]) => {
      this.favourites = this.registrationService.formatRegistrations(_);
      this.loading = false;
    });
    if (!this.sessionService.isLoggedIn()) return;
    this.getFavourites();
  }

  ngOnInit(): void {}

  public openPlateDetail(registration: Registration): void {
    this.router.navigate([`/plate-detail/${registration.registration}`]);
  }

  private getFavourites(): void {
    if (this.favourites.length > 0) return;
    this.loading = true;
    this.userService.getUserFavourites((_: Registration[]) => {
      this.favourites = this.registrationService.formatRegistrations(_);
      this.loading = false;
    });
  }
}
