import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ActivatedRoute, Router } from '@angular/router';
import { DropdownConfig, DropdownOption } from 'src/app/components/custom-dropdown/custom-dropdown.component';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import { SwitchTab } from 'src/app/components/switch-toggle/switch-toggle.component';
import { BYOTermBreakdown } from 'src/app/models/byoTermResponse';
import { Registration } from 'src/app/models/registration';
import { RegistrationService } from 'src/app/services/registration-service';
import {
  SearchReqResponse,
  SearchService,
} from 'src/app/services/search-service';
import {
  TrackingService,
  TRACKING_SEARCH_TYPE,
} from 'src/app/services/tracking-service';
import SwiperCore, { Navigation, Pagination, Swiper, Thumbs } from 'swiper';

SwiperCore.use([Navigation, Thumbs, Pagination]);

export class DatelessSearchResponse {
  constructor(
    public style: string,
    public layout: string,
    public main: Registration[],
    public reversed: Registration[]
  ) { }
}

export class DatelessStyleOptionGroup {
  constructor(
    public name: string,
    public viewName: string,
    public reverseViewName: string,
    public options: DatelessLengthOption[]
  ) { }
}

export class DatelessLengthOption {
  constructor(public viewValue: string, public value: string) { }
}

export class DatelessSearchRequestItem {
  constructor(
    public size: string,
    public search: string,
    public callback: (res: DatelessSearchResponse) => void,
    public canReturn: boolean = true
  ) { }

  public pop(): void {
    this.canReturn = false;
  }
}

@Component({
  selector: 'pla-dateless-search-page',
  templateUrl: './dateless-search-page.component.html',
  styleUrls: ['./dateless-search-page.component.scss'],
})
export class DatelessSearchPageComponent implements OnInit, AfterViewInit {
  public npFeatured = new FeatureType(
    'Recently Added',
    'recently-added'
  );
  public datelessFeatured = new FeatureType('Featured Dateless', 'dateless');
  public activeSwitch = 'letter';
  public tabs: SwitchTab[] = [
    new SwitchTab('Letters First', 'letter'),
    new SwitchTab('Numbers First', 'number'),
    new SwitchTab('Both', 'both'),
  ];

  private requestQueue: DatelessSearchRequestItem[] = [];

  public results: DatelessSearchResponse = new DatelessSearchResponse(
    '',
    '',
    [],
    []
  );
  public allResults: SearchReqResponse;
  public searching: boolean = false;
  public selecting: boolean = false;

  public selectedOption: DatelessLengthOption = null;
  public tableTitle: string;
  public letterFirst: boolean = true;
  public showBoth: boolean = true;

  private lastSearchKey: { length: string; search: string } = null;

  public recalculateInput: EventEmitter<void> = new EventEmitter<void>();
  public main_resultChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public all_resultChange: EventEmitter<SearchReqResponse> =
    new EventEmitter<SearchReqResponse>();
  public prefix: string = '';
  public numbers: string = '';
  public letters: string = '';
  public any_character: string = '?';
  public no_character: string = '﹣';
  public canSearch: boolean = false;

  public lastSearchTime: number = 0;
  public selectionCooldown: number = 1500;
  public queuedDebounce: boolean = false;

  public searchTrigger: EventEmitter<void> = new EventEmitter<void>();
  public changeDropdown: EventEmitter<DropdownOption> = new EventEmitter<DropdownOption>();
  public selectedDropdownOption: DropdownOption;
  public datelessOptionsDropdownConfig: DropdownConfig = new DropdownConfig('Select a format', 'swap_vert', [
    new DropdownOption('*x-', 'One Letter and One Number', { styleName: '1x1', length: 2 }),
    new DropdownOption('*x--', 'One Letter and Two Numbers', { styleName: '1x2', length: 3 }),
    new DropdownOption('*x---', 'One Letter and Three Numbers', { styleName: '1x3', length: 4 }),
    new DropdownOption('*x----', 'One Letter and Four Numbers', { styleName: '1x4', length: 5 }),
    new DropdownOption('**x-', 'Two Letters and One Number', { styleName: '2x1', length: 3 }),
    new DropdownOption('**x--', 'Two Letters and Two Numbers', { styleName: '2x2', length: 4 }),
    new DropdownOption('**x---', 'Two Letters and Three Numbers', { styleName: '2x3', length: 5 }),
    new DropdownOption('**x----', 'Two Letters and Four Numbers', { styleName: '2x4', length: 6 }),
    new DropdownOption('***x-', 'Three Letters and One Number', { styleName: '3x1', length: 4 }),
    new DropdownOption('***x--', 'Three Letters and Two Numbers', { styleName: '3x2', length: 5 }),
    new DropdownOption('***x---', 'Three Letters and Three Numbers', { styleName: '3x3', length: 6 }),
    new DropdownOption('***x----', 'Three Letters and Four Numbers', { styleName: '3x4', length: 7 }),
  ], { showSelected: true, selectOnStart: false });

  private pageStyle: string;
  private searchCriteria: string;
  public termSorting: 'A-Z' | 'Z-A' | 'LOW-TO-HIGH' | 'HIGH-TO-LOW' = 'HIGH-TO-LOW';

  /*
    pageStyle = 3x1 = STYLE OF PLATE
    searchCriteria = -*- = PLATE FILTER
    startingWith = letter/number = STARTING WITH LETTER OR NUMBER
  */

  constructor(
    private searchService: SearchService,
    private registrationService: RegistrationService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private ref: ChangeDetectorRef,
    private trackingService: TrackingService
  ) {
    this.pageStyle = this.route.snapshot.params.length;
    this.searchCriteria = this.route.snapshot.params.searchCriteria;

    console.log(this.pageStyle);

    if (this.searchCriteria == null && this.searchCriteria == undefined) {
      this.canSearch = false;
      return; // nothing selected
    }

    this.trackingService.TrackSearch(
      TRACKING_SEARCH_TYPE.DATELESS,
      this.searchCriteria || '',
      {
        size: this.pageStyle,
      }
    );
    this.canSearch = true;
  }

  ngOnInit(): void {
    this.search();
  }

  ngAfterViewInit(): void {
    this.selectedOption = new DatelessLengthOption('FROM START', this.pageStyle);
    const toSelect = [...this.datelessOptionsDropdownConfig.options];
    const option = toSelect.find(x => x.value === this.pageStyle)
    if (option === undefined) return;
    this.changeDropdown.emit(option)
  }

  public OptionChange(e: DropdownOption): void {
    console.log(e);
    this.selectedDropdownOption = e;
    this.canSearch = true;
    this.selectedOption = new DatelessLengthOption(e.view, e.value)
    this.tableTitle = e.metaData['styleName'];
    this.location.go(this.createUrl());
    this.search();
  }

  public TermSortChange(): void {
    if (this.termSorting === 'A-Z') this.allResults.registrations = this.allResults.registrations.sort((a, b) => a.registration.localeCompare(b.registration));
    else if (this.termSorting === 'Z-A') this.allResults.registrations = this.allResults.registrations.sort((a, b) => b.registration.localeCompare(a.registration));
    else if (this.termSorting === 'HIGH-TO-LOW') this.allResults.registrations = this.allResults.registrations.sort((a, b) => b.price - a.price);
    else if (this.termSorting === 'LOW-TO-HIGH') this.allResults.registrations = this.allResults.registrations.sort((a, b) => a.price - b.price);

    this.all_resultChange.emit({ ...this.allResults });
  }

  private createUrl(): string {
    return `dateless-search/${this.selectedOption.value}`;
  }

  public selectedOptionLength(): number {
    return this.selectedDropdownOption.metaData['length'];
  }

  public filterSearchResults(input: string): void {
    console.log(input);
    const filteredResults = [...this.allResults.registrations].filter(r => r.registration.indexOf(input) > -1);
    this.all_resultChange.emit({ ...this.allResults, registrations: filteredResults });
  }

  /* 
    MAIN = NUM FIRST
    REV  = LET FIRST
  */
  private presentData(): void {
    this.TermSortChange();
    setTimeout(() => {
      this.searching = false;
    }, 100);
  }

  public toggleBoth(): void {
    this.showBoth = !this.showBoth;
  }

  private search(): void {
    if (this.selectedOption == undefined || this.selectedOption == null) return;
    if (this.searchCriteria == null) this.searchCriteria = '';

    // dont research
    var curKey = {
      length: this.selectedOption.value,
      search: this.searchCriteria,
    };

    if (this.lastSearchKey != null) {
      var reversedLength = curKey.length.split('').reverse().join('');
      var reversedSearch = curKey.search.split('*').reverse().join('*');

      var matchesLength =
        this.lastSearchKey.length == reversedLength ||
        this.lastSearchKey.length == curKey.length;

      var matchesSearch =
        this.lastSearchKey.search == reversedSearch ||
        this.lastSearchKey.search == curKey.search;

      if (matchesLength && matchesSearch) {
        return;
      }
    }
    this.lastSearchKey = curKey;
    // end dont research

    this.searching = true;

    var lastRequest = this.requestQueue[0]; // remove first // fifo
    if (lastRequest != null) lastRequest.pop();

    this.makeSearchRequest();
  }

  private formatRespData(res: DatelessSearchResponse): void {
    if (res != null) {
      res.main = this.registrationService.formatRegistrations(res.main);
      res.reversed = this.registrationService.formatRegistrations(res.reversed);
      this.results = res;

      this.allResults = new SearchReqResponse('dateless_id', [
        ...res.main,
        ...res.reversed,
      ]);

      this.presentData();
    } else {
      this.searching = false;
      this.selecting = false;
    }
  }

  private makeSearchRequest(): void {
    var queueItem: DatelessSearchRequestItem = new DatelessSearchRequestItem(
      this.selectedOption.value,
      this.searchCriteria || '',
      (res: DatelessSearchResponse) => {
        if (queueItem.canReturn) this.formatRespData(res);
      }
    );

    this.requestQueue.push(queueItem);
    this.searchService.searchDatelessLength(
      this.selectedOption.value,
      this.searchCriteria || '',
      (res: DatelessSearchResponse) => {
        queueItem.callback(res);
      }
    );
  }

  public searchTitle(header: string): string {
    return this.tableTitle;//`${this.results.style}  (${header})`;
  }
}
