import { Component, OnInit, OnDestroy } from '@angular/core';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { SearchService } from '../services/search-service';

export class RecentlySoldPlate {
  public registration: string;
  public soldPrice: number;
  public soldDate: Date;
}
const SECOND_TO_MINUTES = 60000;
@Component({
  selector: 'pla-price-history-chart',
  templateUrl: './price-history-chart.component.html',
  styleUrls: ['./price-history-chart.component.scss']
})
export class PriceHistoryChartComponent implements OnInit, OnDestroy {

  private root!: am5.Root;
  private series!: am5xy.LineSeries;
  private chart!: am5xy.XYChart;

  private builtTooltip: am5.Tooltip;
  private priceData: RecentlySoldPlate[] = [];
  private visiblePriceData: RecentlySoldPlate[] = [];
  private allPriceData: RecentlySoldPlate[] = [];
  private visibleTimeRange: number = SECOND_TO_MINUTES * (window.innerWidth <= 768 ? 15 : 20);
  public upcoming: RecentlySoldPlate;

  constructor(private searchService: SearchService) {
  }

  ngOnInit(): void {
    this.searchService.getYesterdaySoldResults((results: RecentlySoldPlate[]) => {
      const now = new Date();
      now.setDate(now.getDate() - 1);

      // map everything to YESTERDAY
      results = results.map((p) => {
        p.soldDate = new Date(p.soldDate.toString());
        p.soldDate.setDate(now.getDate());
        p.soldDate.setMonth(now.getMonth());
        p.soldDate.setFullYear(now.getFullYear());
        return p;
      }).sort(a => new Date(a.soldDate).getTime());


      this.allPriceData = results;
      this.priceData = results.filter(p => {
        p.soldDate = new Date(p.soldDate.toString());
        return p.soldDate.getTime() <= now.getTime();
      });

      if (this.allPriceData.length > this.priceData.length) this.upcoming = this.allPriceData[this.priceData.length];

      console.log('today has already passed', this.priceData.length, 'out of', this.allPriceData.length);
      this.createChart();
    })
  }

  private createChart(): void {
    this.root = am5.Root.new("chartdiv", {
      useSafeResolution: false,
    });
    this.root.dateFormatter.set('dateFormat', "HH:mm:ss")
    this.root.numberFormatter.set("numberFormat", "£#,###.00");

    this.root.setThemes([
      am5themes_Animated.new(this.root)
    ]);

    this.chart = this.root.container.children.push(am5xy.XYChart.new(this.root, {
      // maxTooltipDistance: 0,
      // maxTooltipDistanceBy: 'x',
      interactive: false,
    }));
    this.chart.zoomOutButton.hide();

    this.builtTooltip = am5.Tooltip.new(this.root, {
      background: am5.RoundedRectangle.new(this.root, {
        fill: am5.color('#fff'),
        stroke: am5.color('#000'),
        fillOpacity: 0,
        strokeOpacity: 0
      }),
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      html: `<a href="/plate-detail/{registration}" class="text-decoration-none bg-transparent">
        <div class="ms-3 overflow-hidden">
          <!--<small class="d-block w-100 text-center">{valueX.formatDate()}</small>-->
          <p style="background: #FEDE35;" class="mb-1 border border-1 border-dark rounded-3 overflow-hidden inclusive-plate-font text-dark px-2 py-1 fs-md-18 fs-mb-14">{registration}</p>
          <small class="d-block w-100 text-center fw-bolder">{valueY}</small>
        </div>
      </a>`,
      pointerOrientation: 'left',
    });

    const xAxis = this.chart.xAxes.push(am5xy.DateAxis.new(this.root, {
      maxDeviation: 0.5,
      baseInterval: {
        timeUnit: "second",
        count: 1
      },
      renderer: am5xy.AxisRendererX.new(this.root, {}),
    }));
    const yAxis = this.chart.yAxes.push(am5xy.ValueAxis.new(this.root, {
      renderer: am5xy.AxisRendererY.new(this.root, {
      }),
    }));

    xAxis.get('renderer').labels.template.setAll({
      fontFamily: 'Avenir'
    })
    yAxis.get('renderer').labels.template.setAll({
      fontFamily: 'Avenir'
    })

    this.series = this.chart.series.push(am5xy.LineSeries.new(this.root, {
      name: "Sold Price",
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "soldPrice",
      valueXField: "soldDate",
      stroke: am5.color('#3b3b3b'),
      width: 2,
      snapTooltip: true,
      tooltip: this.builtTooltip,
      showTooltipOn: 'always',
    }));
    this.series.appear(100);
    this.series.set('stateAnimationDuration', 100);
    this.series.set('interpolationDuration', 100);
    this.series.set('sequencedInterpolation', true);
    this.series.set('sequencedDelay', 100);


    this.series.get("tooltip")!.get("background")!.events.on("click", (ev) => {
      const registration = this.series.get("tooltip")!.dataItem!.dataContext['registration'];
      if (registration == undefined) return;
      window.location.pathname = `/plate-detail/${registration}`;
    });

    // Add yellow dots for each data point
    this.series.bullets.push(() => {
      return am5.Bullet.new(this.root, {
        locationY: 0.5,
        sprite: am5.Rectangle.new(this.root, {
          // radius: 7,
          width: 6,
          height: 6,
          centerX: 3,
          centerY: 3,
          fill: am5.color('#FEDE35'),
        }),
      });
    });

    const formattedData = this.priceData.map(item => ({
      soldDate: new Date(item.soldDate.toString()).getTime(),
      soldPrice: item.soldPrice / 100,
      registration: item.registration
    }));
    this.series.data.setAll(formattedData.slice(-50, formattedData.length));

    // Initialize cursor
    const cursor = am5xy.XYCursor.new(this.root, {
      active: false,
      visible: false,
      disabled: true,
      behavior: 'none',
      // xAxis: xAxis,
      // yAxis: yAxis,
      // showTooltipOn: 'click',
      interactive: false,
    });
    cursor.lineX.set("visible", false);
    cursor.lineY.set("visible", false);
    // this.chart.set("cursor", cursor);

    // Live update simulation
    setInterval(() => {
      this.addDataPoint();
    }, 1000);

    const _xAxis = this.chart.xAxes.getIndex(0) as am5xy.DateAxis<am5xy.AxisRendererX>;
    setInterval(() => {
      if (_xAxis) {
        this.scrollToLatestPoint(_xAxis);
      }
    }, 500)
  }

  addDataPoint(): void {
    const now = new Date();
    const availablePointIndex = this.allPriceData.findIndex(p => {
      p.soldDate = new Date(p.soldDate.toString());
      const hour = now.getHours();
      const minutes = now.getMinutes();
      const seconds = now.getSeconds();
      var isNow = p.soldDate.getHours() == hour && p.soldDate.getMinutes() == minutes && p.soldDate.getSeconds() == seconds
      return isNow;
    });

    const xAxis = this.chart.xAxes.getIndex(0) as am5xy.DateAxis<am5xy.AxisRendererX>;
    const yAxis = this.chart.yAxes.getIndex(0) as am5xy.ValueAxis<am5xy.AxisRendererY>;
    this.setMinMax(xAxis, yAxis);

    if (availablePointIndex === -1) {
      this.scrollToLatestPoint(xAxis);
      return;
    }

    const availablePoint = this.allPriceData[availablePointIndex];
    if (this.allPriceData.length > availablePointIndex) this.upcoming = this.allPriceData[availablePointIndex + 1];

    this.insertDataPoint(availablePoint);
  }

  insertDataPoint(point: RecentlySoldPlate): void {
    // point.soldPrice = Math.floor(Math.random() * (2000000 - 1000000 + 1) + 1000000);;
    var newPoint = { ...point, soldDate: new Date(point.soldDate) };
    this.priceData.push(newPoint);
    this.series.data.push({
      soldDate: new Date(newPoint.soldDate.toString()).getTime(),
      soldPrice: newPoint.soldPrice / 100,
      registration: newPoint.registration
    });

    if (this.series.data.length > 50) {
      this.series.data.removeIndex(0);
    }
  }

  setMinMax(xAxis: am5xy.DateAxis<am5xy.AxisRendererX>, yAxis: am5xy.ValueAxis<am5xy.AxisRendererY>): void {
    const lastDataPointIndex = this.series.dataItems.length - 1;
    const lastDataItem = this.series.dataItems[lastDataPointIndex];

    if (lastDataItem == undefined) return;

    const lastSoldDate = lastDataItem.get("valueX"); // Retrieves the soldDate value

    const timeRangeSplit = this.visibleTimeRange / 3;
    const maxDate = new Date(lastSoldDate + (timeRangeSplit * 1));
    const minDate = new Date(lastSoldDate - (timeRangeSplit * 2));

    xAxis.set("max", maxDate.getTime());
    xAxis.set("min", minDate.getTime());

    const inView = [...this.series.dataItems].filter(d => {
      var time = d.get('valueX');
      return time > minDate.getTime();
    });
    this.visiblePriceData = [...inView].map((d) => {
      const x = new RecentlySoldPlate();
      x.registration = d.dataContext['registration']
      return x;
    }
    );
    const maxPriceOrder = inView.sort((a, b) => {
      var _a = a.get('valueY');
      var _b = b.get('valueY');
      return _b - _a;
    });
    const maxPrice = maxPriceOrder[0].get('valueY');
    yAxis.set("min", 0);
    yAxis.set("max", maxPrice * 1.05);
    // yAxis.set('logarithmic', (maxPrice * 1.05) > 1000);
    // console.log(maxPrice * 1.05, yAxis.get('logarithmic'));
  }

  scrollToLatestPoint(xAxis: am5xy.DateAxis<am5xy.AxisRendererX>) {
    const lastDataPointIndex = this.series.dataItems.length - 1;
    const lastDataItem = this.series.dataItems[lastDataPointIndex];

    if (lastDataItem) {
      const lastSoldDate = lastDataItem.get("valueX"); // Retrieves the soldDate value

      if (lastSoldDate) {
        this.series.showDataItemTooltip(lastDataItem);
        lastDataItem.component.set('tooltip', this.builtTooltip);
        const tooltip = this.series.get("tooltip");
        if (tooltip) tooltip.show();
      }
    }
  }

  ngOnDestroy(): void {
    this.root.dispose();
  }
}
