<div class="{{classes}}">
  <div class="bg-transparent pt-3 overflow-hidden shadow-sm">
    <div *ngIf="showText">
      <div *ngIf="mainLoader" class="px-2 pb-4">
        <pla-text-changer [texts]="loadingTexts" [duration]="4"></pla-text-changer>
      </div>
      <div *ngIf="!mainLoader" class="d-flex align-items-center justify-content-between w-100 pb-3">
        <small class="mx-2 text-muted align-self-center text-center fw-bold fs-6 w-100 d-block">
          Finalising...
        </small>
      </div>
    </div>
    <pla-custom-loader [progress]="progress" [barColour]="'#888'">
      <div style="transform: translateY(5px);">
        <svg xmlns="http://www.w3.org/2000/svg" width="54" height="13" viewBox="0 0 324.117 77.439">
          <g id="Group_2377" data-name="Group 2377" transform="translate(7763.381 24221.439)">
            <g id="Group_2375" data-name="Group 2375" transform="translate(3 36.617)">
              <g id="Group_2374" data-name="Group 2374" transform="translate(0 -1)">
                <path id="Path_1153" data-name="Path 1153"
                  d="M117.138,53.008,83.955,59.355l-3.229-13.34L52.389,37.861V33.67Z"
                  transform="translate(-7634.871 -24289.906)" fill="#b6202d" />
                <path id="Path_1152" data-name="Path 1152"
                  d="M8.157,29.244l-4.12-8.437L86.294,5.541c.042,0,34.382-2.883,36.089-2.9l22.684,2.178,1.182,1.4h-4.825L127.048,8.553l-6.375,9.055,95.965,11.453,32.826-6.615s23.746,2.1,28.333,3.1c-.972-.279,15.581,5.316,27.538,11.391s20.155,11.924,19.758,14.32c-.1.617-4.631,6.762-4.631,6.762l5.458,4.672L317.6,65.531l6.381,1.563,1.934,2.424-14.74,4.005h-36.12s10.779-20.6,0-33.531c-4.761-5.71-16.312-9.177-19.8-9.442-11-.835-19.734,7.015-21.459,9.442-8.893,12.506-3.737,30.678-3.737,30.678s-78.955,1.406-143.77,0l2.2-12.281s.531-10.35-5.062-18.4S66.8,28.427,63.585,28C53.07,26.6,41.7,29.406,35.132,39.992s-1.8,29.416-1.8,29.416L12.107,66.041,1.8,45.656Z"
                  transform="translate(-7768.183 -24259.695)" fill="#b6202d" />
                <circle id="Ellipse_806" data-name="Ellipse 806" cx="22.5" cy="22.5" r="22.5"
                  transform="translate(-7731.221 -24227.178)" fill="none" />
                <circle id="Ellipse_808" data-name="Ellipse 808" cx="21" cy="21" r="21"
                  transform="translate(-7534.959 -24224.178)" fill="none" />
                <line id="Line_3138" data-name="Line 3138" x2="2" y2="18" transform="translate(-7624.5 -24254.5)"
                  fill="none" />
                <path id="Path_1234" data-name="Path 1234"
                  d="M52.389,46.416l7.636-1.56,1.847-7.988,3.323,1.182L63.7,44.137l6.546-1.311,1.508-5.375,11.017,1.207,2.339-1.207L71.058,33.332l-10-1.014S45.4,31.035,44,32.318s11.446,5.133,11.446,5.133Z"
                  transform="translate(-7810.122 -24286.1)" fill="#b6202d" />
              </g>
              <rect id="Rectangle_7582" data-name="Rectangle 7582" width="2" height="15"
                transform="translate(-7625 -24254.5)" fill="#b6202d" />
            </g>
            <g id="Ellipse_867" data-name="Ellipse 867" transform="translate(-7730 -24193)" fill="none" stroke="#b6202d"
              stroke-width="1">
              <circle cx="24.5" cy="24.5" r="24.5" stroke="none" />
              <circle cx="24.5" cy="24.5" r="24" fill="none" />
            </g>
            <g id="Ellipse_868" data-name="Ellipse 868" transform="translate(-7534 -24191)" fill="none" stroke="#b6202d"
              stroke-width="1">
              <circle cx="22.5" cy="22.5" r="22.5" stroke="none" />
              <circle cx="22.5" cy="22.5" r="22" fill="none" />
            </g>
          </g>
        </svg>
      </div>
    </pla-custom-loader>
  </div>
</div>