<div class="page">
  <pla-main-nav-chips></pla-main-nav-chips>
  <div class="content-container extended">
    <div class="ps-5 side-container mb-3">
      <div class="pt-0 position-relative">
        <pla-side-nav></pla-side-nav>
      </div>

      <div class="bg-light py-3">
        <pla-featured-plates [featureType]="npFeatured" [plateSize]="'smaller'" [priceFontSize]="16" [maxCount]="10"
          [sortBy]="'price'" [plateClass]="'flat shaded'" [showIndex]="true"></pla-featured-plates>
      </div>
    </div>
    <div class="center-content-container position-relative">
      <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
        <div *ngIf="false">
          <!-- BREADCRUMBS -->
          <div class="w-100">
            <div
              class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
              <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
              <span>/</span>
              <a style="color: #939393; white-space: pre" class="text-decoration-none d-block"
                [routerLink]="['/dateless-search']">Dateless</a>
            </div>
          </div>
        </div>

        <pla-banner-image [carBanner]="'FERRARI_275'" [mobileCarBanner]="'FERRARI_275_MB'" [rawImage]="true"
          [backgroundImage]="true" [backgroundClass]="'compare-mobile-background'">
          <div class="compare-banner-height">
            <div class="d-flex d-md-flex align-items-start justify-content-between flex-column">
              <div class="p-4 p-md-4">
                <p class="lh-sm fw-bold fs-md-28 fs-mb-24 text-white">DATELESS - Search by style and length</p>
              </div>
            </div>
          </div>
        </pla-banner-image>
      </div>

      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-2 px-md-1 py-0 me-0 me-md-2">
        <div>
          <div class="px-0 px-md-2 mt-2 w-100 mx-auto" style="max-width: 1000px">
            <div class="px-4 px-md-3">
              <div class="mx-auto" style="max-width: 350px">
                <pla-custom-dropdown [baseClass]="'bg-white border border-1'" [closedClass]="'rounded-3 border'"
                  [openedClass]="
                    'rounded-top-3 border-start border-top border-end border-bottom-transparent'
                  " [optionClass]="
                    'border-1 border border-dark border-start border-end border-bottom rounded-bottom-3 overflow-hidden'
                  " (change)="OptionChange($event)" [forceSelect]="changeDropdown"
                  [config]="datelessOptionsDropdownConfig"></pla-custom-dropdown>
              </div>
            </div>
          </div>
          <div *ngIf="canSearch && !searching">
            <div class="px-0 px-md-2 mt-4 mb-3 w-100 mx-auto" style="max-width: 1000px">
              <div class="px-4 px-md-3">
                <div class="w-100 mx-auto search-input" style="max-width: 400px">
                  <div class="mb-3 mx-auto rounded-2 overflow-hidden plate-background flat shaded position-relative">
                    <div class="mx-auto d-flex align-items-stretch justify-content-between py-2">
                      <div class="bg-transparent w-100 p-1">
                        <pla-new-search-input [emitOnInput]="true" [placeholder]="'Filter Results'"
                          [searchTexts]="['Initials', 'Date', 'Age']" [internalRouting]="false"
                          [searchTrigger]="searchTrigger"
                          (searchEvent)="filterSearchResults($event)"></pla-new-search-input>
                      </div>
                      <!-- <button (click)="searchTrigger.emit()" style="max-width: 56px"
                        class="w-100 fs-md-15 fs-mb-15 btn text-dark d-flex align-items-center justify-content-center px-2 rounded-0 gap-1">
                        <mat-icon class="">search</mat-icon>
                      </button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="canSearch; else infoSection">
          <div class="my-3 mx-auto w-100">
            <div class="d-flex align-items-center justify-content-end py-1 px-1 overflow-auto">
              <select class="mx-0 w-100 d-block" style="max-width: 250px; border-color: #214953 !important"
                [(ngModel)]="termSorting" (change)="TermSortChange()">
                <option [ngValue]="'A-Z'" selected>A-Z</option>
                <option [ngValue]="'Z-A'">Z-A</option>
                <option [ngValue]="'HIGH-TO-LOW'">Price High to Low</option>
                <option [ngValue]="'LOW-TO-HIGH'">Price Low to High</option>
              </select>
            </div>
          </div>
          <!-- RESULT SECTION -->
          <div class="pla_card no-card mobile spacing">
            <!-- SEARCH RESULTS -->
            <pla-search-result-table [searching]="searching || selecting" [searchType]="''"
              [searchTitle]="searchTitle('dateless combined')" [resultType]="'MATCHES'" [pluralResultType]="'MATCHES'"
              [results]="allResults" [resultChange]="all_resultChange"
              [paginationPageSize]="500"></pla-search-result-table>
          </div>
        </div>

        <ng-template #loadingSpinner>
          <div class="full spinner-loader">
            <div>
              <mat-progress-spinner class="spinner cyan" mode="indeterminate" [color]="'#327b8d'"
                [diameter]="60"></mat-progress-spinner>
            </div>
          </div>
        </ng-template>

        <ng-template #infoSection>
          <div class="pla_card no-card mobile spacing padding">
            <div class="px-0">
              <div>
                <div class="text-title heading">
                  <h2 class="border-bottom mb-1">
                    <b class="strong">Dateless-By-Size</b>
                  </h2>
                </div>
                <div class="text-section">
                  <div class="text-paragraph">
                    <p>
                      This useful tool searches all the
                      <pla-glossary-term-word [term]="'Dateless Registration'"
                        [text]="'Dateless'"></pla-glossary-term-word>
                      number plates specifically by the number of characters.
                      Enter letters or numbers into the plate search box to
                      refine the results further or leave blank to review all
                      plates available in that series.
                    </p>
                  </div>
                </div>

                <div class="text-title heading mt-3">
                  <h2 class="border-bottom mb-1">
                    <b class="strong">Did you know...</b>
                  </h2>
                </div>
                <div class="text-section">
                  <div class="text-paragraph">
                    <p class="indent">
                      Single/Single or 1/1 registrations are the most highly
                      sought-after registrations and command incredible prices.
                      The pinnacle of these are the number ‘1’ registration of
                      which there are only 46 combinations available. The most
                      expensive 1/1 registration currently available is
                      <pla-plate class="d-inline-block" [customClass]="'flat shaded'" [rawPlate]="'F 1'"
                        [useCustom]="true" [customSize]="9" [customFontSize]="17" [inlineText]="true"></pla-plate>
                    </p>
                  </div>
                </div>

                <div class="text-section mt-3">
                  <div class="text-paragraph">
                    <div class="faq-item">
                      <mat-icon>help_outline</mat-icon>
                      <h4>Can I put a Dateless registration on my vehicle?</h4>
                    </div>
                    <p class="indent">
                      You are not allowed to make a vehicle appear younger by
                      transferring a more recent registration. However,
                      <pla-glossary-term-word [term]="'Dateless Registration'"
                        [text]="'Dateless'"></pla-glossary-term-word>
                      registrations have no date indicator so they can be
                      transferred to any age vehicle.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <!-- FEATURED LISTING -->
            <div class="featured-container mt-5">
              <div class="px-0">
                <h2 class="border-bottom mb-1">Featured Dateless</h2>
              </div>
              <div class="mt-2">
                <pla-featured-plates [featureType]="datelessFeatured" [showTitle]="false" [plateSize]="'smaller'"
                  [priceFontSize]="16" [sortBy]="'price'" [plateClass]="'flat shaded'"></pla-featured-plates>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>