import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'pla-text-changer',
  templateUrl: './text-changer.component.html',
  styleUrls: ['./text-changer.component.scss']
})
export class TextChangerComponent implements OnInit {

  @Input() public customClasses: string;
  @Input() public loopText: boolean = false;
  @Input() public texts: string[] = [];
  @Input() public transition: 'fade';
  @Input() public duration: number = 2;

  private index: number = 0;
  private swapTimer: number = -1

  constructor() { }

  ngOnInit(): void {
    window.clearInterval(this.swapTimer);
    this.swapTimer = window.setInterval(() => {
      this.index++;
      if (this.index >= this.texts.length) {
        if (this.loopText) this.index = 0;
        else this.index--;
      }
    }, this.duration * 1000)
  }


  public TextToShow(): string {
    return this.texts[this.index];
  }
}
