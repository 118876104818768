<div
  class="d-flex align-items-center justify-content-between flex-sm-row flex-column"
  style="max-width: 650px; min-height: 300px"
>
  <div
    class="align-self-stretch flex-fill w-100 d-sm-none d-block"
    style="
      min-width: 200px;
      min-height: 230px;
      background-image: url('https://cdn.platex.co.uk/images/woman_on_phone_landscape.jpg');
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;
    "
  ></div>
  <div class="p-2 p-sm-4">
    <!-- <pla-platex-direct [colour]="'default'"></pla-platex-direct> -->
    <div class="py-2">
      <p class="text-muted fs-6 mb-0">100% FREE</p>
      <p class="fw-bold fs-4 special-title">Sign Up Now</p>
      <p class="text-muted fst-italic">
        Get full access to the <strong class="fw-bold">millions</strong> of
        registration plates available on the market today!
      </p>
      <div class="mt-2 mb-4">
        <div class="d-flex align-items-center gap-2 mb-1">
          <mat-icon style="color: #29576a">check</mat-icon>
          <p class="fs-6 mb-0">Favourite your best plates for later</p>
        </div>
        <div class="d-flex align-items-center gap-2 mb-1">
          <mat-icon style="color: #29576a">check</mat-icon>
          <p class="fs-6 mb-0">Set-up alerts for relavent terms</p>
        </div>
        <div class="d-flex align-items-center gap-2 mb-1">
          <mat-icon style="color: #29576a">check</mat-icon>
          <p class="fs-6 mb-0">Get notified for that perfect plate</p>
        </div>
        <div class="d-flex align-items-center gap-2 mb-1">
          <mat-icon style="color: #29576a">check</mat-icon>
          <p class="fs-6 mb-0">Access to our latest features</p>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end gap-2">
        <button
          mat-flat-button
          (click)="OpenSignUpModal()"
          class="w-50 platex-button"
          style="width: fit-content !important"
        >
          Sign Up
        </button>
        <button
          mat-stroked-button
          (click)="Close()"
          class="w-50 platex-border-button"
        >
          Continue As Guest
        </button>
      </div>
    </div>
  </div>
  <div
    class="align-self-stretch flex-fill w-25 d-none d-sm-block"
    style="
      min-width: 200px;
      background-image: url('https://cdn.platex.co.uk/images/man_on_phone.jpg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    "
  ></div>
</div>
