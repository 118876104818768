<div *ngIf="no_character && any_character" [ngClass]="{ 'search-area': withPadding }">
  <div class="search-container search-input-sizing">
    <div *ngIf="characterEntry" class="plate-background flat shaded plate-surround smaller character-options"
      [ngClass]="{ 'full-width': fullWidth }">
      <!-- pad left hand side -->
      <div *ngIf="(!emitOnChange || forceSearchButton) && padLeft">
        <div class="searchIcon" matPrefix></div>
      </div>

      <div class="plate-style byo-container">
        <ng-container *ngIf="style != 'any_text'; else freeInput">
          <ng-container *ngFor="let character of characterEntry">
            <div class="plate-select-container" [style.margin-left]="
                character && character.spaceBefore ? '10px' : 'initial'
              " [style.border-color]="
                character && character.selectedCharacter == no_character
                  ? 'rgba(0, 0, 0, 0.1)'
                  : 'rgba(0, 0, 0, 0.25)'
              ">
              <select (change)="eventChangeHandler()" [style.color]="
                  character && character.selectedCharacter == no_character
                    ? 'rgba(0, 0, 0, 0.1)'
                    : 'black'
                " [style.font-size]="
                  character && character.selectedCharacter == no_character
                    ? '25px'
                    : 'inherit'
                " [style.width]="
                  character && character.selectedCharacter == no_character
                    ? 'initial'
                    : '100%'
                " [(ngModel)]="character.selectedCharacter" [ngClass]="{ 'safari-dropdown': platform?.SAFARI }"
                class="plate-select">
                <option *ngFor="let characterOption of character.characterOptions" [value]="characterOption"
                  [style.color]="
                    characterOption == no_character ? 'red' : 'black'
                  ">
                  {{ characterOption }}
                </option>
              </select>
            </div>
          </ng-container>
        </ng-container>

        <ng-template #freeInput>
          <ng-container *ngFor="let character of characterEntry; let i = index">
            <div class="plate-select-container text" style="border-color: rgba(0, 0, 0, 0.25); margin-left: initial"
              (keydown)="EnterSubmit($event)">
              <input type="text" style="
                  text-align: center;
                  width: 100%;
                  color: black;
                  font-size: inherit;
                " id="text_input_{{ i }}" autocomplete="false" maxlength="1" (keyup)="textInputHandler($event, i)"
                [(ngModel)]="character.selectedCharacter" class="plate-select" [pattern]="validationRegex" />
            </div>
          </ng-container>
        </ng-template>
      </div>

      <div *ngIf="!emitOnChange || forceSearchButton">
        <div class="spinner-icon-button searchIcon">
          <mat-icon *ngIf="!loading" matSuffix (click)="search()"
            [ngStyle]="{ color: searchButtonColour }">search</mat-icon>
          <ng-container *ngIf="loading">
            <mat-progress-spinner class="spinner black" mode="indeterminate" [color]="'#000'"
              [diameter]="30"></mat-progress-spinner>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- <div *ngIf="!emitOnChange || forceSearchButton">
      <mat-icon
        class="searchIcon"
        matSuffix
        (click)="search()"
        [ngStyle]="{ color: searchButtonColour }"
        >search</mat-icon
      >
    </div> -->
  </div>

  <!-- CUSTOM MESSAGE -->
  <div *ngIf="message">
    <p style="text-align: center">{{ message }}</p>
  </div>

  <!-- DISABLED TEXT -->
  <div style="margin: 10px auto 0" *ngIf="false && showMessage">
    <p style="text-align: center; display: flex; justify-content: space-evenly">
      <span>
        '<span>{{ any_character }}</span>' = Any character in position
      </span>
      <span>'<span>{{ no_character }}</span>' = No character in position
      </span>
    </p>
  </div>

  <!-- <div
    style="margin: 10px auto; text-align: center"
    *ngIf="!emitOnChange || forceSearchButton"
  >
    <button class="searchButton cyan-button" mat-flat-button (click)="search()">
      Search
    </button>
  </div> -->
</div>