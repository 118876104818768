<div class="page pt-2 pt-lg-4">
    <div class="content-container extended">
        <div class="ps-5 side-container mb-3">
            <div class="pt-0 position-relative">
                <div class="bg-light text-dark p-3 mb-4" *ngIf="registrationString">
                    <pla-side-bar-filters [filters]="compareFilters"></pla-side-bar-filters>
                </div>

                <pla-side-nav></pla-side-nav>
            </div>

            <div class="bg-light py-3">
                <pla-featured-plates [featureType]="rsFeatured" [plateSize]="'smaller'" [priceFontSize]="16"
                    [maxCount]="10" [sortBy]="'price'" [plateClass]="'flat shaded'"
                    [showIndex]="false"></pla-featured-plates>
            </div>
        </div>
        <div class="center-content-container position-relative">
            <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
                <div>
                    <!-- BREADCRUMBS -->
                    <div class="w-100">
                        <div *ngIf="searchedTerm"
                            class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
                            <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
                            <span>/</span>
                            <a [style.color]="registrationString ? '#327b8d' : '#939393'" style="white-space: pre"
                                class="text-decoration-none d-block" [routerLink]="[searchableUrl()]">{{
                                searchedTerm }} Results</a>
                            <span *ngIf="registrationString">/</span>
                            <span *ngIf="registrationString" style="color: #939393; white-space: pre"
                                class="text-decoration-none d-block">{{
                                registrationString }}</span>
                        </div>

                        <div *ngIf="!searchedTerm"
                            class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
                            <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
                            <span *ngIf="registrationString">/</span>
                            <span *ngIf="registrationString" style="color: #939393; white-space: pre"
                                class="text-decoration-none d-block">{{
                                registrationString }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div
                class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-0 px-md-1 py-0 me-0">
                <div *ngIf="registrationString"
                    class="pla_card mobile spacing no-top no-card no-left no-right position-relative py-0">
                    <div class="px-2 px-md-1 mx-auto" style="max-width: 1000px">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12 col-md-6 order-2 order-md-1">
                                    <div
                                        class="w-100 h-100 p-3 text-center d-flex flex-column justify-content-center align-items-center">
                                        <div>
                                            <pla-plate [rawPlate]="formattedRegistration" [white]="false"
                                                [mobileSmall]="false" [openModal]="false" [showPrice]="false"
                                                [useCustom]="true" [customClass]="'flat shaded'" [customSize]="9"
                                                [customFontSize]="40"></pla-plate>
                                        </div>

                                        <div *ngIf="detail">
                                            <p class="text-center mt-3 mb-0" *ngIf="detail.min !== 0">
                                                Prices From
                                            </p>
                                            <p class="mb-0" *ngIf="detail.min !== 0 && detail.min != detail.max">
                                                <strong class="fs-5">{{
                                                    detail.min | currency : "GBP"
                                                    }}</strong>
                                                to
                                                <strong class="fs-5">{{
                                                    detail.max | currency : "GBP"
                                                    }}</strong>
                                            </p>
                                            <p class="mb-0" *ngIf="detail.min !== 0 && detail.min == detail.max">
                                                <strong class="fs-5">{{
                                                    detail.min | currency : "GBP"
                                                    }}</strong>
                                            </p>
                                            <small class="text-muted">Details last updated on
                                                {{ detail.lastUpdate | date : "dd/MM/yy" }}</small>
                                        </div>

                                        <div>
                                            <a *ngIf="showFullSearch" style="cursor: pointer;" class="mt-3 search_all_link"
                                                (click)="searchAllClickHandler()">Search more "{{ registrationString }}"
                                                plates</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-12 col-md-6 order-1 order-md-2">
                                    <div class="overflow-hidden bg-light position-relative" style="border-radius: 25px">
                                        <img class="w-100 d-block m-auto" style="max-height: 6000px"
                                            *ngIf="selectedImage && selectedImage.image" [src]="selectedImage.image" />
                                        <div class="position-absolute top-0 end-0 p-2" *ngIf="favouritePlate">
                                            <div class="bg-white rounded-circle d-flex align-items-center justify-content-center"
                                                style="width: 35px; height: 35px;">
                                                <pla-inline-favourite [showText]="false" [force]="'favourite'"
                                                    [plate]="favouritePlate"></pla-inline-favourite>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- PLATE INFO -->
                        </div>
                    </div>

                    <pla-sticky-tabs [config]="stickyLinkConfig" [classes]="'pt-3 pb-3'"></pla-sticky-tabs>
                    <div>
                        <div class="pb-3 w-100 position-relative" style="background-color: #F1F4F2"
                            id="compare-deals-content">
                            <div class="position-absolute" style="top: -92px" id="compare-deals"></div>
                            <pla-tp-sellers (PriceRangeChange)="PriceRangeChange($event)"
                                (resultsChange)="CompareResultsChange($event)"
                                (compareFilters)="SetCompareFilters($event)" [useCachedResults]="true"
                                [registration]="registrationString"></pla-tp-sellers>
                        </div>

                        <div class="pt-3 pb-3 w-100 px-3 px-md-1 position-relative border-top" id="history-content">
                            <div class="position-absolute" style="top: -92px" id="history"></div>
                            <div class="px-0 px-md-4 mx-auto" style="max-width: 700px">
                                <div>
                                    <p class="mb-2 fs-5">Price History</p>
                                    <p>
                                        The price graph shows price changes over time including both
                                        price changes and verified sales.
                                    </p>
                                </div>
                                <div>
                                    <table *ngIf="detail.plateDetail" class="text-start w-100 my-3 ms-0 me-auto"
                                        style="max-width: 350px">
                                        <tr>
                                            <td class="fs-6 fw-bolder pe-2">Registration Style</td>
                                            <td class="fs-6">{{ detail.plateDetail.style }}</td>
                                        </tr>
                                        <tr>
                                            <td class="fs-6 fw-bolder pe-2">First Issued</td>
                                            <td class="fs-6">
                                                {{ detail.plateDetail.issueMonth }}
                                                {{ detail.plateDetail.issueYear }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="fs-6 fw-bolder pe-2">Region</td>
                                            <td class="fs-6">{{ detail.plateDetail.issueRegion }}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="bg-white p-3 shadow-sm rounded-3">
                                    <pla-price-history *ngIf="registrationLoaded"
                                        [registration]="registrationString"></pla-price-history>
                                </div>
                            </div>
                        </div>

                        <div class="pt-3 pb-3 w-100 px-3 px-md-1 position-relative border-top"
                            id="market-insight-content">
                            <div class="position-absolute" style="top: -92px" id="market-insight"></div>
                            <div class="px-0 px-md-4 mx-auto" style="max-width: 700px">
                                <div>
                                    <p class="mb-2 fs-5">Market Insight</p>
                                    <p>
                                        These are the key market insights of the current style "69"
                                        series and current he entire style of cherished registration.
                                    </p>
                                </div>
                                <pla-market-insight *ngIf="registrationLoaded && detail.loaded"
                                    [registration]="registrationString"
                                    [cheapestRegPrice]="detail.min"></pla-market-insight>
                            </div>
                        </div>

                        <div class="pt-3 pb-3 w-100 px-3 px-md-1 position-relative bg-white border-top"
                            id="faq-content">
                            <div class="position-absolute" style="top: -92px" id="faq"></div>
                            <div>
                                <p class="mb-2 fs-5">FAQ's</p>
                                <pla-faq [faq]="faq"></pla-faq>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>