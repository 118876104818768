<div class="page pt-2 pt-md-4">
  <div class="content-container extended">
    <div class="ps-5 side-container mb-3">
      <div class="pt-0 position-relative">
        <pla-side-nav></pla-side-nav>
      </div>

      <div class="bg-light py-3">
        <pla-featured-plates [featureType]="rsFeatured" [plateSize]="'smaller'" [priceFontSize]="16" [maxCount]="10"
          [sortBy]="'price'" [plateClass]="'flat shaded'" [showIndex]="false"></pla-featured-plates>
      </div>
    </div>
    <div class="center-content-container position-relative">
      <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
        <div>
          <!-- BREADCRUMBS -->
          <div class="w-100">
            <div
              class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
              <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
              <span>/</span>
              <a [style.color]="breadcrumbRelease ? '#327b8d' : '#939393'" style="white-space: pre"
                class="text-decoration-none d-block" [routerLink]="['/new-release']">New Release</a>
              <span *ngIf="breadcrumbRelease">/</span>
              <span *ngIf="breadcrumbRelease" style="color: #939393; white-space: pre"
                class="text-decoration-none d-block">{{ breadcrumbRelease }}</span>
            </div>
          </div>
        </div>

        <pla-banner-image [carBanner]="getBannerInfo().desktopImage" [mobileCarBanner]="getBannerInfo().mobileImage"
          [rawImage]="true" [backgroundImage]="true" [backgroundClass]="
            'desktop-mobile-background mobile-banner-height d-flex'
          " [breakPoint]="500">
          <div class="banner-tint new-banner-height d-flex align-items-stretch justify-content-start flex-fill">
            <div class="d-flex align-items-start justify-content-start">
              <div class="p-4 p-md-4">
                <p class="lh-sm fw-bold fs-md-45 fs-mb-45 text-white mb-0">
                  {{ releaseYear }} Series
                </p>
                <p class="lh-sm fs-md-28 fs-mb-24 text-white mb-0">
                  {{ isFutureRelease() ? "COMING SOON" : "AVAILABLE NOW" }}
                </p>
              </div>
            </div>
          </div>
        </pla-banner-image>

        <div *ngIf="showAllSeries"
          class="my-3 mx-auto w-100 border-start-0 border-end-0 border-1 border-top border-bottom"
          style="background: #f8f8f8; border-color: #214953 !important">
          <div id="release-year-selector"
            class="d-flex align-items-center justify-content-between py-1 px-1 overflow-auto" style="gap: 10px;">
            <ng-container *ngFor="let releaseItem of colourBannerMap">
              <p id='release-year-{{releaseItem.year}}' (click)="GotoYear(releaseItem.year)"
                class="my-0 rounded-3 fs-md-21 fs-mb-16 plate-font selectableYear text-decoration-none d-flex align-items-center justify-content-center"
                [ngClass]="{ selectedYear: releaseYear == releaseItem.year }" style="aspect-ratio: 1">
                {{ releaseItem.year }}
              </p>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="pb-3 pt-0 pt-md-3">
        <div class="container-fluid mx-auto w-100">
          <div class="px-0 mt-4">
            <p class="fs-md-20 fs-mb-20 text-start lh-sm" style="color: #214953">
              The Treasure Hunt Starts Here!
            </p>
            <p class="fs-md-14 fs-mb-14 text-start text-md-start mb-3">
              Released in {{ releaseMonth }} 20{{ releaseYear }}, the "{{
              releaseYear
              }}
              SERIES" plates are for vehicles registered from 1st
              {{ releaseMonth }} 20{{ releaseYear }} onwards. Popular with new
              car owners and private plate investors, these registrations also
              make great gifts for births or significant birthdays. You can buy
              them even without a new vehicle, as they are held on a V750
              Certificate of Entitlement, valid for 10 years and renewable.
            </p>
            <p class="fs-md-14 fs-mb-14 text-start text-md-start mb-0">
              Before each release, the DVLA assesses which combinations to ban
              due to potential offensiveness and identifies unique numbers to
              hold back for auction. Despite this, nearly 8 million new
              combinations ensure some valuable private plates often slip
              through!
            </p>
          </div>

          <div class="s-spacing"></div>
        </div>
      </div>

      <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-0 px-md-1 py-0 me-0">
        <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative py-0">
          <pla-sticky-tabs [config]="stickyLinkConfig"></pla-sticky-tabs>
          <div style="background-color: transparent">
            <div class="pt-5 pb-5 w-100 px-3 px-md-1 position-relative border-top" id="terms-content">
              <div class="position-absolute" style="top: -92px" id="terms"></div>
              <div class="px-0 px-md-4 mx-auto">
                <div class="mb-4">
                  <p class="fw-bold mb-4 fs-md-16">
                    Perfect Private Plates for the {{ releaseYear }} Series
                  </p>
                  <p class="fs-md-14">
                    A "perfect plate" is a private plate that represents a word
                    in the most recognisable way possible. These plates are
                    extremely rare and highly sought after.
                  </p>
                </div>

                <div class="my-3 mx-auto w-100">
                  <div class="d-flex align-items-center justify-content-end py-1 px-1 overflow-auto">
                    <select class="mx-0 w-100 d-block" style="max-width: 200px; border-color: #214953 !important"
                      [(ngModel)]="termSorting" (change)="TermSortChange()">
                      <option [ngValue]="'A-Z'" selected>A-Z</option>
                      <option [ngValue]="'Z-A'">Z-A</option>
                      <option [ngValue]="'HIGH-TO-LOW'">Price High to Low</option>
                      <option [ngValue]="'LOW-TO-HIGH'">Price Low to High</option>
                    </select>
                  </div>
                </div>

                <ng-container *ngIf="termReleaseResults.loading">
                  <div
                    class="d-flex align-items-center justify-content-evenly justify-content-md-between gap-3 flex-wrap px-0 mb-3">
                    <ng-template *ngFor="let item of [].constructor(16)"
                      [ngTemplateOutlet]="loadingPlate"></ng-template>
                  </div>
                </ng-container>
                <div *ngIf="!termReleaseResults.loading">
                  <div
                    class="d-flex align-items-center justify-content-between justify-content-md-between gap-3 flex-wrap px-0">
                    <!-- ABOVE -->
                    <ng-container *ngIf="getBannerInfo().result">
                      <ng-container *ngFor="let plate of resultsBefore()">
                        <ng-template [ngTemplateOutlet]="plateRow"
                          [ngTemplateOutletContext]="{ plate: plate }"></ng-template>
                      </ng-container>
                    </ng-container>

                    <!-- IMAGE -->
                    <div *ngIf="getBannerInfo().result"
                      class="d-flex align-items-stretch justify-content-between justify-content-md-between gap-3 px-0 flex-wrap">
                      <div *ngIf="resultsDuring().length > 0"
                        class="result-list-container d-flex align-items-stretch justify-content-between justify-content-md-between gap-3 flex-wrap px-0">
                        <ng-container *ngFor="let plate of resultsDuring()">
                          <ng-template [ngTemplateOutlet]="plateRow"
                            [ngTemplateOutletContext]="{ plate: plate }"></ng-template>
                        </ng-container>
                      </div>

                      <div
                        class="result-image-container flex-fill overflow-hidden rounded-lg position-relative d-flex justifty-content-between align-items-stretch flex-column">
                        <div class="position-absolute top-0 end-0 p-md-3 p-2">
                          <pla-plate [rawPlate]="
                              getBannerInfo().result.formattedRegistration
                            " [showPrice]="false" [changeHover]="false" [mobileSmall]="true"
                            [customClass]="'flat shaded'" [plateSize]="'nr-s'" [smallPlate]="false"
                            [showPercentage]="false" [noMargin]="true"></pla-plate>
                        </div>
                        <img src="{{ getBannerInfo().resultImage }}" class="w-100 d-block" />
                        <div [ngStyle]="{ background: getBannerInfo().colour }"
                          class="py-2 px-4 d-flex align-items-center justify-content-between flex-fill">
                          <p class="my-0 text-white">
                            {{ getBannerInfo().result.term }}
                          </p>
                          <p class="my-0 text-white">
                            {{
                            getBannerInfo().result.owner == 0
                            ? "Brand New Issue"
                            : "Resale"
                            }}
                          </p>
                          <p class="my-0 text-white">
                            From
                            {{
                            getBannerInfo().result.price / 100
                            | currency : "GBP" : "symbol" : "1.0-0"
                            }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <!-- AFTER -->
                    <ng-container *ngFor="let plate of resultsAfter()">
                      <ng-template [ngTemplateOutlet]="plateRow"
                        [ngTemplateOutletContext]="{ plate: plate }"></ng-template>
                    </ng-container>

                    <!-- <div class="w-100 flex-fill">
                                            <p class="my-3 text-muted text-center w-75 mx-auto">A "perfect plate" is a private plate that represents a word in the most recognisable way possible. These plates are extremely rare and highly sought after</p>
                                        </div> -->

                    <div class="flex-fill px-2 h-0">
                      <pla-plate [blank]="true" [customClass]="'flat fixed'"></pla-plate>
                    </div>
                    <div class="flex-fill px-2 h-0">
                      <pla-plate [blank]="true" [customClass]="'flat fixed'"></pla-plate>
                    </div>
                    <div class="flex-fill px-2 h-0">
                      <pla-plate [blank]="true" [customClass]="'flat fixed'"></pla-plate>
                    </div>
                    <div class="flex-fill px-2 h-0">
                      <pla-plate [blank]="true" [customClass]="'flat fixed'"></pla-plate>
                    </div>
                  </div>
                </div>

                <pla-page-placement [placement]="termPlacement"></pla-page-placement>

                <div class="container-fluid mt-5 mb-0 px-0">
                  <div class="row">
                    <div class="col-12 col-md-2"></div>
                    <div class="col-12 col-md-8">
                      <div class="p-3 text-white d-flex align-items-center justify-content-center flex-column rounded-3"
                        [ngStyle]="{ background: getBannerInfo().colour }">
                        <p class="text-white fs-md-14 fs-mb-14 text-center mb-0">
                          We currently have #,###,### '{{ releaseYear }}'
                          registrations available to choose from
                        </p>
                        <div>
                          <pla-byo-search-page [style]="'current'" [breakdowns]="seriesBYO" [searchDefault]="false"
                            [showSwitch]="false" [inlineSearch]="true" [gotoResults]="true"></pla-byo-search-page>
                        </div>

                        <p class="text-white fs-md-14 fs-mb-14 text-center mb-0">
                          Simply select two letters, the number
                          {{ releaseYear }}, then three further letters to make
                          up your ideal new style reg from the
                          {{ releaseYear }} series.
                        </p>
                      </div>
                    </div>
                    <div class="col-12 col-md-2"></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pt-5 pb-5 w-100 px-3 px-md-1 position-relative border-top" id="names-content">
              <div class="position-absolute" style="top: -92px" id="names"></div>
              <div class="px-0 px-md-4 mx-auto">
                <div class="mb-4">
                  <p class="fw-bold mb-4 fs-md-16">
                    {{ releaseYear }} Series Private Name Plates
                  </p>
                  <p class="fs-md-14">
                    Whether you want to personalise your new car or buy a gift
                    for a loved one there are literally thousands of
                    combinations for you to choose from. Here is just a small
                    selection of name plates to help provide some inspiration.
                  </p>
                </div>

                <div class="my-3 mx-auto w-100 border-start-0 border-end-0 border-1 border-top border-bottom"
                  style="background: #f8f8f8; border-color: #214953 !important">
                  <div class="d-flex align-items-center justify-content-between py-1 px-1 overflow-auto">
                    <ng-container *ngFor="let letter of alphabet">
                      <button class="border border-2 bg-transparent fs-md-20 fs-mb-16" (click)="setAlphabet(letter)"
                        style="aspect-ratio: 1; color: {{
                          currentAlphabet == letter ? '#214953' : '#969696'
                        }} !important;
                                                border-color: {{
                          currentAlphabet == letter ? '#214953' : '#F8F8F8'
                        }} !important;">
                        {{ letter }}
                      </button>
                    </ng-container>
                  </div>
                </div>

                <ng-container *ngIf="nameReleaseResults.loading">
                  <div
                    class="d-flex align-items-center justify-content-evenly justify-content-md-between gap-3 flex-wrap px-0 mb-3">
                    <ng-template *ngFor="let item of [].constructor(16)"
                      [ngTemplateOutlet]="loadingPlate"></ng-template>
                  </div>
                </ng-container>
                <div *ngIf="!nameReleaseResults.loading">
                  <div
                    class="d-flex align-items-center justify-content-evenly justify-content-md-between gap-3 flex-wrap px-0">
                    <ng-container *ngFor="let plate of namesFiltered">
                      <ng-template [ngTemplateOutlet]="plateRow"
                        [ngTemplateOutletContext]="{ plate: plate }"></ng-template>
                    </ng-container>

                    <div class="flex-fill px-2 h-0">
                      <pla-plate [blank]="true" [customClass]="'flat fixed'"></pla-plate>
                    </div>
                    <div class="flex-fill px-2 h-0">
                      <pla-plate [blank]="true" [customClass]="'flat fixed'"></pla-plate>
                    </div>
                    <div class="flex-fill px-2 h-0">
                      <pla-plate [blank]="true" [customClass]="'flat fixed'"></pla-plate>
                    </div>
                    <div class="flex-fill px-2 h-0">
                      <pla-plate [blank]="true" [customClass]="'flat fixed'"></pla-plate>
                    </div>
                  </div>
                </div>

                <pla-page-placement [placement]="namePlacement"></pla-page-placement>
              </div>
            </div>

            <div class="pt-5 pb-5 w-100 px-3 px-md-1 position-relative border-top" id="market-information-content">
              <div class="position-absolute" style="top: -92px" id="market-information"></div>
              <div class="px-0 px-md-4 mx-auto">
                <div>
                  <p class="fw-bold mb-2 fs-md-16">
                    New Issue Private Registrations
                  </p>
                  <p class="fs-md-14 mb-3">
                    Investing in new, never previously registered private plates
                    presents a unique opportunity for significant added value.
                    Each year, the DVLA releases a new series of number plates
                    to the public at fixed prices. While the DVLA does hold back
                    several plates for auction—believing these will fetch higher
                    prices through competitive bidding—there remain numerous
                    opportunities within the general release for astute buyers
                    to find valuable plates.
                  </p>
                </div>
                <div class="pt-5">
                  <!-- CONTENT -->
                  <pla-new-release-market-data [year]="releaseYear"></pla-new-release-market-data>
                </div>
              </div>
            </div>

            <div class="pt-5 pb-5 w-100 px-3 px-md-1 position-relative bg-white border-top" id="faq-content">
              <div class="position-absolute" style="top: -92px" id="faq"></div>
              <div>
                <div class="pb-5 pt-5">
                  <p class="text-center text-md-start fs-md-26 fs-mb-22">
                    Frequently Asked Questions
                  </p>
                </div>
                <div style="height: 50px"></div>
                <pla-faq [faq]="faq"></pla-faq>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingPlate>
  <div
    class="d-flex align-items-center justify-content-center flex-column flex-fill border rounded p-2 plate-result-container skeleton">
    <div class="w-100 mx-auto" style="max-width: 230px">
      <div class="w-100 d-flex align-items-center justify-content-between mb-2 mx-auto">
        <div style="width: 30px; height: 30px"></div>
        <a class="skeleton-item fw-bolder my-0 text-truncate lh-sm text-decoration-none fs-md-14 lh-sm d-block w-100 text-center mx-auto"
          style="max-width: fit-content; color: transparent">LOADING</a>
        <div class="" style="width: 30px; height: 30px"></div>
      </div>
      <div class="">
        <pla-plate [loading]="true" [showPrice]="false" [changeHover]="false" [mobileSmall]="true"
          [customClass]="'flat shaded'" [plateSize]="'nr-s'" [smallPlate]="false" [showPercentage]="false"
          [noMargin]="true" [white]="false" [termFirst]="false" [showIndex]="false"
          [showSoldRibbon]="false"></pla-plate>
      </div>
      <div class="d-flex mx-auto w-100 mt-2 mb-0 align-items-center justify-content-between">
        <span class="skeleton-item flex-fill rounded-3 p-1 fs-md-12 w-100 lh-sm"
          style="line-height: 30px; color: transparent">£ LOADING</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #plateRow let-plate="plate">
  <div
    class="d-flex align-items-center justify-content-center flex-column flex-fill border rounded p-2 plate-result-container">
    <div class="w-100 mx-auto" style="max-width: 230px">
      <div class="w-100 d-flex align-items-center justify-content-between mb-2 mx-auto">
        <div style="width: 30px; height: 30px"></div>

        <a routerLink="/search-results/{{ plate.term }}" class="fw-bolder my-0 text-truncate lh-sm text-decoration-none fs-md-14 lh-sm d-block w-100 text-center {{
            plate.owner == -1 ? 'text-muted' : 'text-dark'
          }}">{{ plate.term }}</a>

        <div *ngIf="plate.owner == 3" class="" style="width: 30px; height: 30px; max-height: 30px">
          <div class="svg-icn d-block w-100" style="max-height: 30px">
            <svg xmlns="http://www.w3.org/2000/svg" class="d-block w-100" width="30" height="30"
              viewBox="0 0 103.604 103.604">
              <path id="currency_pound_24dp_FILL0_wght400_GRAD0_opsz24" class="tofill"
                d="M240-835.84v-2.612l.634-.326a7.5,7.5,0,0,0,1.383-.963,6.814,6.814,0,0,0,1.363-1.632,4.293,4.293,0,0,0,.615-2.3,3.941,3.941,0,0,0-.058-.686q-.058-.326-.134-.62H240v-2.612h2.3q-.807-1.078-1.555-2.269a5.452,5.452,0,0,1-.749-2.955,6.448,6.448,0,0,1,2.458-5.093A8.937,8.937,0,0,1,248.449-860a9.188,9.188,0,0,1,4.839,1.273,7.418,7.418,0,0,1,3.034,3.3l-2.842,1.012a4.732,4.732,0,0,0-1.939-2.138,5.813,5.813,0,0,0-3.092-.833,5.674,5.674,0,0,0-3.8,1.339,4.1,4.1,0,0,0-1.575,3.232,3.8,3.8,0,0,0,.922,2.612,18.843,18.843,0,0,1,1.882,2.612h6.414v2.612h-5.338a3.187,3.187,0,0,1,.1.62q.019.326.019.686a6.327,6.327,0,0,1-.672,2.938,9.348,9.348,0,0,1-1.632,2.285h7.528a3.525,3.525,0,0,0,2.343-.686,3.523,3.523,0,0,0,1.114-1.763l2.688,1.143a4.475,4.475,0,0,1-2.17,2.857,7.506,7.506,0,0,1-3.975,1.061Z"
                transform="translate(-197.416 904.142)" fill="#007850" />
              <g id="restart_alt_24dp">
                <g id="Group_1932" data-name="Group 1932">
                  <path id="Path_962" data-name="Path 962" d="M0,0H103.6V103.6H0Z" fill="none" />
                </g>
                <g id="Group_1934" data-name="Group 1934" transform="translate(17.267 10.792)">
                  <g id="Group_1933" data-name="Group 1933">
                    <path id="Path_963" data-name="Path 963" class="tofill"
                      d="M12.634,47.827a25.811,25.811,0,0,1,7.6-18.3l-6.13-6.13A34.531,34.531,0,0,0,34.218,82.059v-8.72A25.93,25.93,0,0,1,12.634,47.827Zm60.435,0A34.525,34.525,0,0,0,38.535,13.292c-.259,0-.518.043-.777.043L42.463,8.63,36.376,2.5,21.267,17.609,36.376,32.718l6.087-6.087L37.8,21.969c.259,0,.518-.043.734-.043a25.887,25.887,0,0,1,4.317,51.413v8.72A34.485,34.485,0,0,0,73.069,47.827Z"
                      transform="translate(-4 -2.5)" fill="#007850" />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
        <div *ngIf="plate.owner != 3" class="" style="width: 30px; height: 30px"></div>
      </div>
      <div>
        <pla-plate [plate]="plate" [showPrice]="false" [changeHover]="false" [hoverText]="plate.term"
          [mobileSmall]="true" [customClass]="'flat shaded'" [plateSize]="'nr-s'" [smallPlate]="false"
          [showPercentage]="false" [noMargin]="true" [disabled]="plate.owner == -1" [white]="false" [termFirst]="false"
          [showIndex]="false" [secondaryText]="plate.formattedRegistration" [showSoldRibbon]="false"></pla-plate>
      </div>
      <div class="d-flex mx-auto w-100 mt-2 mb-0 align-items-center justify-content-between">
        <span class="flex-fill rounded-3 p-1 fs-md-12 w-100 lh-sm" style="line-height: 30px" *ngIf="plate.owner > -2"
          [ngStyle]="{ color: plate.owner == -1 ? '#860202' : '#214953' }">{{ plate.owner == -1 ? "SOLD" : "FROM" }}
          {{ plate.price / 100 | currency : "GBP" : "symbol" : "1.0-0" }}</span>

        <!-- ACTIONS -->
        <div *ngIf="plate.owner <= -1"
          class="flex-shrink-0 d-flex align-items-center justify-content-end gap-1 pointer notify-btn px-1 rounded-2"
          [ngClass]="{ set: plate.notified }" (click)="notifyRegistration(plate)">
          <span>{{ plate.notified ? "Notified" : "Notify" }}</span>
        </div>
        <div *ngIf="plate.owner !== -1"
          class="flex-shrink-0 d-flex align-items-center justify-content-end gap-1 pointer favourite-icn px-0 rounded-2"
          [ngClass]="{ set: plate.favourite }" (click)="favouriteRegistration(plate)">
          <!-- <span>{{ plate.favourite ? 'Saved' : 'Save'}}</span> -->
          <mat-icon class="small icon">{{
            plate.favourite ? "favorite" : "favorite_border"
            }}</mat-icon>
        </div>
      </div>
    </div>
  </div>
</ng-template>