<div class="page">
  <div class="content-container extended">
    <div class="ps-5 side-container mb-3">
      <div class="pt-0 position-relative">
        <pla-side-nav></pla-side-nav>
      </div>

      <div class="bg-light py-3">
        <pla-featured-plates [featureType]="npFeatured" [plateSize]="'smaller'" [priceFontSize]="16" [maxCount]="10"
          [sortBy]="'price'" [plateClass]="'flat shaded'" [showIndex]="true"></pla-featured-plates>
      </div>
    </div>
    <div class="center-content-container position-relative">
      <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative">
        <div>
          <!-- BREADCRUMBS -->
          <div class="w-100">
            <div
              class="border-bottom d-flex align-items-center justify-content-start gap-2 w-100 px-3 px-lg-0 pb-2 pt-0">
              <a style="color: #327b8d" class="d-block text-decoration-none" [routerLink]="['/']">Home</a>
              <span>/</span>
              <a style="color: #939393; white-space: pre" class="text-decoration-none d-block"
                [routerLink]="['/buy']">Buy</a>
            </div>
          </div>
        </div>

        <pla-banner-image [carBanner]="'BMW_M2'" [rawImage]="true" [backgroundImage]="true"
          [backgroundClass]="'desktop-mobile-background'">
          <div class="d-flex align-items-center justify-content-center flex-column p-4 p-md-4 new-banner-height">
            <!-- HEADER TEXT -->
            <div class="text-white text-center w-100 align-self-center justify-content-center">
              <p class="h1 mb-1 fw-bold">Buy</p>
              <div>
                <p class="fw-light fs-6" style="color: #facf15">
                  The Smarter Search
                </p>
              </div>
            </div>
          </div>
        </pla-banner-image>
        <div class="search-input-container">
          <div class="search-area inner">
            <div class="box-container">
              <pla-option-search [optionsEnabled]="false" [endpoint]="'search-results'" [searchCriteria]="''"
                [plateStyle]="true"></pla-option-search>
              <span class="header">Search a word or registration</span>
            </div>
          </div>
        </div>
      </div>

      <div
        class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-2 px-md-1 py-0 me-0 me-md-2">
        <div class="px-0 px-md-4">
          <p class="w-100 d-block fw-light text-start">
            Our most comprehensive search powered by our proprietary algorithm
            “PLATO”. In just a few seconds PLATO can review, retrieve and rank
            tailored results from over 68 million registrations offered for sale
            across the market including dealers, private owners and the DVLA.
          </p>
          <p class="w-100 d-block fw-light text-start">
            When possible PLATO will show you the Perfect Plate for your
            searched word. These rare plates are a direct match with no
            additional characters and where any numerical letters are highly
            recognisable, such as the plate J4 MES for James. You can see the
            more Perfect Plates with our unique
            <a href="/names">names</a> feature.
          </p>

          <div class="py-3"></div>

          <div class="text-title heading mt-3">
            <h2 class="border-bottom mb-1">
              <b class="strong">Plate Info</b>
            </h2>
          </div>
          <div class="text-section">
            <div class="text-paragraph">
              <p class="indent">
                By selecting the
                <pla-plate class="d-inline-block me-1" [rawPlate]="'PLA 7E'" [openModal]="false" [useCustom]="true"
                  [customSize]="9" [customFontSize]="17" [inlineText]="true"></pla-plate>
                or
                <button mat-raised-button class="ms-1 cyan-button d-inline-block" style="height: 28px">
                  <div style="
                      color: white !important;
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    ">
                    <span style="color: white; line-height: 12px" class="inline">View</span>
                    <mat-icon class="small text-white">arrow_right</mat-icon>
                  </div>
                </button>
                button you can access more detailed information about the
                registration including the original date of release, the issue
                area, sale price history you can also compare prices to find the
                best deal for that registration.
              </p>
            </div>
          </div>

          <div class="text-title heading mt-3">
            <h2 class="border-bottom mb-1">
              <b class="strong">Alerts</b>
            </h2>
          </div>
          <div class="text-section">
            <div class="text-paragraph">
              <p class="indent">
                After each new search PLATO will offer you the chance to setup
                an '🔔 Alert'. Once activated, we will send you a message when a
                matching registration comes to the market giving you the best
                chance to secure it!
              </p>
            </div>
          </div>

          <div class="text-title heading mt-3">
            <h2 class="border-bottom mb-1">
              <b class="strong">Help Make Plato Even Better</b>
            </h2>
          </div>
          <div class="text-section">
            <div class="text-paragraph">
              <p class="indent">
                PLATO is an autonomous algorithm which continues to learn and
                adapt with every search it undertakes. However you can also help
                PLATO learn to improve the quality of ranking by using vote Up
                or Down icons beneath each result. If you think a registration
                should feature higher up the ranking simpliy click the 👍 and if
                lower the thumbs down 👎. If you have any other comments or
                suggestion how PLATO could be even better we’d love to hear from
                you via the Feedback tab.
              </p>
            </div>
          </div>

          <!-- FEATURED LISTING -->
          <div class="featured-container mt-3">
            <h2 class="border-bottom mb-1">
              <b class="strong">Featured</b>
            </h2>
            <div>
              <pla-featured-plates [showTitle]="false" [plateClass]="'flat shaded'"></pla-featured-plates>
            </div>
          </div>

          <div class="pla_card mobile spacing no-top no-card no-left no-right position-relative px-0 px-md-1 py-0 me-0">
            <div class="px-0 px-md-2">
              <div class="pb-5 pt-5">
                <p class="text-center text-md-start fs-md-26 fs-mb-22">
                  Frequently Asked Questions
                </p>
              </div>
              <div class="w-100 px-3 px-md-0 pb-5">
                <pla-faq [faq]="faq"></pla-faq>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content-container" *ngIf="false">
    <pla-banner-image class="w-100" [rawImage]="true" [backgroundImage]="true" [carBanner]="'AVENTADOR_S_FOOTER'"
      [backgroundClass]="'w-100 desktop-mobile-bottom'">
      <div class="d-flex align-items-stretch justify-content-end new-banner-height">
        <div
          class="w-75 w-md-100 p-4 ps-5 ps-md-4 d-flex align-items-center justify-content-center ltr_gradient md-no-gradient">
          <div class="d-flex align-items-center justify-content-center flex-column ms-auto me-0"
            style="max-width: 450px">
            <p class="fs-5 text-white">
              With the entire market of registrations on one single portal,
              industry leading optimised search facilities and even price
              comparison it's no wonder buyers are choosing PlateX to find their
              perfect plate.
            </p>

            <div class="align-self-end mt-4">
              <button class="ms-auto me-0 turquoise-button" mat-flat-button>
                Begin Free Trial
              </button>
            </div>
          </div>
        </div>
      </div>
    </pla-banner-image>
  </div>
</div>