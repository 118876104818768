import { ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FeatureType } from 'src/app/components/featured-plates/featured-plates.component';
import { SidebarFilterConfig } from 'src/app/components/side-bar-filters/side-bar-filters.component';
import { StickyConfig, StickyLink, StickyLinkGroup } from 'src/app/components/sticky-tabs/sticky-tabs.component';
import { CompareFilters } from 'src/app/components/tp-sellers/tp-sellers.component';
import { FormattedPlateResponse } from 'src/app/models/formattedPlateResponse';
import { PlateDetail } from 'src/app/models/plateDetail';
import { Registration } from 'src/app/models/registration';
import { ImageKeyPair, ImageService } from 'src/app/services/image-service';
import { RegistrationService } from 'src/app/services/registration-service';
import { TrackingService } from 'src/app/services/tracking-service';
import { UserService } from 'src/app/services/user-service';

@Component({
  selector: 'pla-plate-detail-page',
  templateUrl: './plate-detail-page.component.html',
  styleUrls: ['./plate-detail-page.component.scss']
})
export class PlateDetailPageComponent implements OnInit {

  public searchedTerm: string = null;
  public registrationString: string = null;
  public formattedRegistration: string = null;
  public favouritePlate: Registration;
  public registrationLoaded: boolean = false;
  public showFullSearch: boolean = false;
  public notified: boolean = false;
  public notifiedFetch: boolean = false;
  public stickyLinkConfig: StickyConfig = new StickyConfig(
    [new StickyLinkGroup(
      [
        new StickyLink('Prices', 'compare-deals', true),
        new StickyLink('History', 'history', false),
        new StickyLink('Market Insights', 'market-insight', false),
        new StickyLink('Q&As', 'faq', false),
      ])],
    new EventEmitter<void>()
  );
  public rsFeatured = new FeatureType('Recently Sold', 'recently-sold');
  public selectedImage: ImageKeyPair = undefined;
  public compareFilters = new EventEmitter<
    SidebarFilterConfig<CompareFilters>
  >();

  public detail: {
    min: number;
    max: number;
    lastUpdate: Date;
    plateDetail: PlateDetail;
    loaded: boolean;
  } = {
      min: 0,
      max: 0,
      lastUpdate: new Date(),
      plateDetail: undefined,
      loaded: false
    };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private registrationService: RegistrationService,
    private imageService: ImageService,
    private userService: UserService,
    private changeRef: ChangeDetectorRef,
    private trackingService: TrackingService
  ) {
    this.registrationString = this.activatedRoute.snapshot.params.registration;
    this.searchedTerm = this.activatedRoute.snapshot.params.term;
    this.showFullSearch =
      this.activatedRoute.snapshot.queryParams['_l'] == 'direct';
    if (this.registrationString == '' || this.registrationString == null)
      this.router.navigate(['/landing']);

    this.registrationString = this.registrationString
      .toUpperCase()
      .replace(' ', '');
    this.userService.getUserNotifies(() => {
      this.notified = this.registrationService.isNotify(
        this.registrationString
      );
      this.notifiedFetch = true;
    });
  }

  ngOnInit(): void {
    this.GetDetail();
    this.formatInput();
  }

  public CompareResultsChange(firstLoad: boolean): void {
    console.log('compare results change', this.formattedRegistration, this.detail)
    this.registrationService.formatRegistrationFromString(this.formattedRegistration, this.detail.min, this.detail.min > -1, (_r) => {
      this.favouritePlate = _r;
    });
    if (firstLoad) return;
    setTimeout(() => {
      this.stickyLinkConfig.reScroll.emit();
    }, 100);
  }

  public SetCompareFilters(
    compareFilters: SidebarFilterConfig<CompareFilters>
  ): void {
    this.compareFilters.emit(compareFilters);
  }


  public PriceRangeChange(data: { min: number; max: number }): void {
    this.detail = { ...this.detail, min: data.min, max: data.max, loaded: true };
  }

  private GetDetail(): void {
    this.registrationService
      .getPlateDetail(this.registrationString)
      .subscribe((d) => {
        this.detail = { ...this.detail, plateDetail: d };
      });
  }

  private formatInput(): void {
    this.registrationLoaded = false;
    this.registrationService
      .FormatRegistration(this.registrationString)
      .subscribe((formattedResp: FormattedPlateResponse) => {
        this.registrationLoaded = true;
        if (
          formattedResp.formattedRegistration == null ||
          formattedResp.formattedRegistration == ''
        )
          return;
        this.formattedRegistration = formattedResp.formattedRegistration;
        this.generateQuickImage();
      });
  }

  private generateQuickImage(): void {
    if (this.formattedRegistration == '') this.formattedRegistration = 'PLA 73X';
    this.imageService.INIT();
    this.imageService.quickBuild(
      this.imageService.getQuickConfig(),
      this.formattedRegistration,
      (image: ImageKeyPair) => {
        this.selectedImage = image;
      }
    );
  }

  public searchableUrl(): string {
    return `/search-results/${this.registrationString}`;
  }
  public searchAllClickHandler(): void {
    this.router.navigate([`/search-results/${this.registrationString}`]);
  }

}
